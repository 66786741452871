import clsx from 'clsx'
import * as yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import InputMask from 'react-input-mask'
import { Form, Input, Button, Upload, Modal, Tag, message } from 'antd'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Heading } from 'components/heading'
import Error from 'components/Error'
import DatePicker from 'components/DatePicker'
import SingleSelect from 'components/SingleSelect'
import ReCAPTCHA from 'react-google-recaptcha'

import axios from 'utils/axios'

import './styles.scss'
import 'antd/dist/antd.css'
import styles from './styles.module.scss'
import { UploadOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const FormPage = () => {
  const history = useHistory()
  const [problemPlace, setProblemPlace] = useState([])
  const [startDate] = useState(moment().subtract(5, 'minutes'))

  const captchaRef = useRef(null)

  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: ''
  })
  const [loading, setLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const getProblemPlace = async () => {
    const res = await axios.get('/client/ticket/problemplace')
    setProblemPlace(res.data)
  }

  useEffect(() => {
    if (!problemPlace.length) {
      getProblemPlace()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsPlaces = useMemo(() => {
    return problemPlace.map((item) => ({ id: item.id, label: item.name }))
  }, [problemPlace])

  const handleSave = async (value, arg) => {
    try {
      setLoading(true)
      const data = value
      const now = moment().format('YYYY-MM-DD HH:mm:ss.SSSZZ')
      data.formFilledDate = now
      const formData = new FormData()
      for (let key in value) {
        if (key === 'photo') {
          if (value[key]) {
            for (let i = 0; i < value[key].length; i++) {
              formData.append(key, value[key][i].originFileObj)
            }
          } else {
            formData.append(key, value[key])
          }
        } else if (key === 'detectedAt') {
          formData.append(
            key,
            moment(value[key]).format('YYYY-MM-DD HH:mm:ss.SSSZZ')
          )
        } else {
          formData.append(key, value[key])
        }
      }
      const res = await axios.put('/client/ticket/create', formData)
      const link = res.data?.link?.split('ticket/')[1]
      history.push(`/ticket/${link}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handlePreview = (file) => {
    if (file.size > 600000) {
      return false
    }
    setPreview({
      visible: true,
      image: file.thumbUrl,
      title: file.name
    })
  }

  const handleCancel = () => setPreview({ ...preview, visible: false })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleChangeDate = (name) => (value) =>
    setFieldValue(name, moment(value))

  const handleChangeSelect = (name) => (value) => setFieldValue(name, value)

  const handleChangePhoto = (name) => (value) => {
    setFieldValue(name, value.fileList)
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: (values) => handleSave(values),
    initialValues: {
      clientName: '',
      clientEmail: '',
      clientPhone: '',
      kiosk: '',
      photo: undefined,
      problemPlaceId: '',
      description: '',
      detectedAt: startDate,
      captcha: null
    },
    validationSchema: yup.object().shape({
      clientName: yup.string().required('Это поле обязательно'),
      clientEmail: yup.string().email('Неверный формат'),
      captcha: yup.mixed().required('Это поле обязательно'),
      clientPhone: yup
        .string()
        .required('Это поле обязательно')
        .matches(
          /^(\+7|7|8)?[\s/-]?\(?[4893][0-9]{2}\)?[\s/-]?[0-9]{3}[\s/-]?[0-9]{2}[\s/-]?[0-9]{2}$/,
          'Неверный формат'
        ),
      problemPlaceId: yup.string().required('Это поле обязательно'),
      kiosk: yup.string().when('problemPlaceId', {
        is: '1',
        then: yup.string().required('Это поле обязательно')
      }),

      photo: yup.mixed().notRequired().yup,
      description: yup.string().required('Это поле обязательно'),
      detectedAt: yup.string().required('Это поле обязательно')
    }),
    enableReinitialize: true
  })

  const handlerErrorMessage = () => {
    messageApi.open({
      type: 'error',
      content: 'Загружаемый файл больше допустимого размера 5 мегабайт',
      duration: 8
    })
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles.number}>
          <span className={styles.numberLabel}>
            Контактный номер техподдержки{' '}
          </span>
          <a href='tel:79011112355' className={styles.bold}>
            +7-901-111-23-55
          </a>
        </div>
      </div>
      <div className={styles.formContainer}>
        <Heading size={3} text={'Обращение в Техподдержку Maxbonus'} />

        <Form onFinish={handleSubmit} layout='vertical'>
          <Form.Item
            required
            label='Ваше Имя'
            extra={touched.clientName && <Error message={errors.clientName} />}
          >
            <Input
              value={values.clientName}
              onChange={handleChange('clientName')}
            />
          </Form.Item>

          <Form.Item
            required
            label='Контактный номер телефона'
            extra={
              touched.clientPhone && <Error message={errors.clientPhone} />
            }
          >
            <InputMask
              value={values.clientPhone}
              className={styles.inputMask}
              mask={'+7-(999)-999-99-99'}
              onChange={handleChange('clientPhone')}
            />
          </Form.Item>

          <Form.Item
            label='Контактный Email'
            extra={
              touched.clientEmail && <Error message={errors.clientEmail} />
            }
          >
            <Input
              value={values.clientEmail}
              onChange={handleChange('clientEmail')}
            />
          </Form.Item>

          <Form.Item
            required
            label='Место возникновения проблемы'
            extra={
              touched.problemPlaceId && (
                <Error message={errors.problemPlaceId} />
              )
            }
          >
            <SingleSelect
              options={optionsPlaces}
              value={values.problemPlaceId}
              onChange={handleChangeSelect('problemPlaceId')}
            />
          </Form.Item>
          {values.problemPlaceId === 1 && (
            <Form.Item
              required
              label='Киоск'
              extra={touched.kiosk && <Error message={errors.kiosk} />}
            >
              <Input
                value={values.kiosk}
                onChange={handleChange('kiosk')}
                placeholder='Адрес включая город/№ киоска'
              />
            </Form.Item>
          )}
          <Form.Item
            required
            label={`Дата и время возникновения проблемы`}
            extra={touched.detectedAt && <Error message={errors.detectedAt} />}
          >
            <DatePicker
              selected={values.detectedAt._d}
              timeIntervals={30}
              onChange={handleChangeDate('detectedAt')}
            />
          </Form.Item>

          <Form.Item
            required
            label='Опишите проблему и ситуацию'
            extra={
              touched.description && <Error message={errors.description} />
            }
          >
            <Input.TextArea
              value={values.description}
              onChange={handleChange('description')}
              autoSize={{ minRows: 5 }}
            />
          </Form.Item>

          <Form.Item
            label='Файлы'
            extra={touched.photo && <Error message={errors.photo} />}
          >
            <Upload
              listType='picture'
              defaultFileList={values.photo}
              onChange={handleChangePhoto('photo')}
              onPreview={handlePreview}
              supportServerRender={null}
              multiple
              maxCount={10}
              beforeUpload={(file) => {
                if (file.size > 600000) {
                  handlerErrorMessage('error')
                  return true
                }
                return false
              }}
            >
              <Button icon={<UploadOutlined />}>Загрузить файлы</Button>
            </Upload>

            <div className={styles.notif}>
              <Tag>Максимальное количество файлов 5</Tag>
              <Tag>Максимальный размер файла 5МБ</Tag>
            </div>
          </Form.Item>

          <Form.Item
            required
            extra={touched.captcha && <Error message={errors.captcha} />}
          >
            <Input value={values.captcha} type='hidden' />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
              ref={captchaRef}
              onChange={(e) => {
                setFieldValue('captcha', e)
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 9 }}>
            <Button loading={loading} htmlType='submit' type='primary'>
              Отправить
            </Button>
          </Form.Item>
        </Form>

        <Modal
          visible={preview.visible}
          title={preview.title}
          onCancel={handleCancel}
          footer={null}
        >
          <img
            alt={preview.title}
            style={{ width: '100%' }}
            src={preview.image}
          />
        </Modal>

        {contextHolder}
      </div>
    </>
  )
}

export default FormPage

export const DoubleRow = (props) => {
  const { firstPart, secondPart, secondSmall = false } = props
  return (
    <div>
      <p className={clsx(styles.rowP, styles.firstRow)}>{firstPart}</p>
      <p className={clsx(styles.rowP, secondSmall && styles.smallP)}>
        {secondPart}
      </p>
    </div>
  )
}
