import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { paramsStringify, parseParams } from 'utils/helpers'

const useStateFromQuery = (initState, keyCbs) => {
  const history = useHistory()

  const state = useState(() => {
    const query = history.location.search
    if (query) {
      const values = parseParams(query, keyCbs)
      if (values) return values
    }
    return initState
  })

  const stateValue = state[0]

  useEffect(() => {
    history.push(paramsStringify(stateValue))
  }, [stateValue, history])

  return state
}

export default useStateFromQuery
