import * as yup from 'yup'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Form, Input, Button } from 'antd'

import Error from 'components/Error'

import axios from 'utils/axios'

import { useProfile } from 'hooks/useContext'

import 'antd/dist/antd.css'
import styles from './styles.module.scss'

const tailLayout = {
  wrapperCol: {
    offset: 9,
    span: 16
  }
}

const Login = () => {
  const [loading, setLoading] = useState(false)

  const { login } = useProfile()

  const handleSave = async (value) => {
    try {
      setLoading(true)
      const res = await axios.post('/auth', value)
      login(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: (values) => handleSave(values),
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required('Это поле обязательно')
        .email('Некорректный email'),
      password: yup.string().required('Это поле обязательно')
    }),
    enableReinitialize: true
  })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <div className={styles.loginContainer}>
      <Form layout='vertical' className={styles.form} onFinish={handleSubmit}>
        <Form.Item
          label='Логин'
          required
          name='email'
          extra={touched.email && <Error message={errors.email} />}
        >
          <Input
            placeholder='email'
            value={values.email}
            onChange={handleChange('email')}
          />
        </Form.Item>

        <Form.Item
          required
          label='Пароль'
          name='password'
          extra={touched.password && <Error message={errors.password} />}
        >
          <Input.Password
            placeholder='Пароль'
            value={values.password}
            onChange={handleChange('password')}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button loading={loading} type='primary' htmlType='submit'>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Login
