import { Modal } from 'antd'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import styles from './styles.module.scss'

export const MetaModal = ({ meta, isOpen, onClose, isJson }) => {
  const [data] = useState(meta)
  const [showText, setShowText] = useState(false)

  const copyRow = useCallback((ev) => {
    console.log(ev.target.outerText)
    navigator.clipboard.writeText(ev.target.outerText)
    setShowText(true)
    setTimeout(() => setShowText(false), 1000)
  }, [])

  return (
    <Modal visible={isOpen} onCancel={onClose} footer={false}>
      {isJson && (
        <>
          <div className={clsx(styles.text, showText && styles.visibleText)}>
            Текс скопирован!
          </div>
          <div className={styles.metaInfo}>
            {data?.adr?.country ? (
              <>
                <p>Киоск id</p>
                <p onClick={copyRow} className={styles.copyRow}>
                  {data.uid}
                </p>

                <p>Номер киоска</p>
                <p onClick={copyRow} className={styles.copyRow}>
                  {data.number}
                </p>
              </>
            ) : (
              <>
                <p>Чек</p>
                <p onClick={copyRow} className={styles.copyRow}>
                  {data.uid}
                </p>

                <p>Клиент</p>
                <p onClick={copyRow} className={styles.copyRow}>
                  {data.clientUID}
                </p>

                <p>Киоск</p>
                <p onClick={copyRow} className={styles.copyRow}>
                  {data.kioskUid}
                </p>
              </>
            )}
          </div>
        </>
      )}

      {!isJson && (
        <div className={styles.stringMeta}>
          <div className={styles.titleMeta}>Дополнительная информация</div>
          <div>{meta}</div>
        </div>
      )}
    </Modal>
  )
}
