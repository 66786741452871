import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input } from 'antd'

import Error from 'components/Error'
import ConditionalRender from 'components/ConditionalRender'

import axios from 'utils/axios'
import { formatTime } from 'utils/helpers'

import { useProfile } from 'hooks/useContext'

import styles from './styles.module.scss'
import { UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

const { Meta } = Card

export const Comments = ({ data }) => {
  const { user } = useProfile()

  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState({
    is: false,
    id: ''
  })

  const getComments = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/comment/${data.id}`)
      setComments(res.data.comments)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getComments()
  }, [getComments])

  const handleSave = async (value) => {
    try {
      setLoading(true)
      editing.is
        ? await axios.put(`/comment/${editing.id}`, value)
        : await axios.post(`/comment/${data.id}`, value)
      resetForm()
      setEditing({ is: false, id: '' })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      getComments()
    }
  }

  const handleEditComment = (id, comment) => () => {
    setEditing({ is: true, id })
    setFieldValue('comment', comment)
  }

  const handleDeleteComment = (id) => async () => {
    try {
      await axios.delete(`/comment/${id}`)
      getComments()
    } catch (e) {
      console.log(e)
    }
  }

  const handleCancelEditing = () => {
    resetForm()
    setEditing({ is: false, id: '' })
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        comment: ''
      },
      validationSchema: yup.object().shape({
        comment: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <div className={styles.commentTabContainer}>
      <div className={styles.commentsContainer}>
        {comments.length ? (
          comments.map((comment) => (
            <Card
              loading={loading}
              key={comment.id}
              actions={
                comment.user?.id === user.id
                  ? [
                      <EditOutlined
                        onClick={handleEditComment(comment.id, comment.comment)}
                        key='edit'
                      />,
                      <DeleteOutlined
                        onClick={handleDeleteComment(comment.id)}
                        key='delete'
                      />
                    ]
                  : null
              }
              className={styles.commentCard}
            >
              <Meta
                avatar={<UserOutlined className={styles.userAvatar} />}
                title={
                  <div className={styles.commentTitle}>
                    <p className={styles.commentName}>{comment.user.name}</p>
                    <p className={styles.commentTime}>
                      {new Date(comment.updatedAt).getTime() ===
                      new Date(comment.createdAt).getTime()
                        ? formatTime(comment.updatedAt)
                        : `ред. ${formatTime(comment.updatedAt)}`}
                    </p>
                  </div>
                }
                description={
                  <div className={styles.comment}>{comment.comment}</div>
                }
              />
            </Card>
          ))
        ) : (
          <div>Комментарии отсутствуют</div>
        )}
      </div>

      <ConditionalRender roles={[1, 2]}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item
            required
            extra={touched.comment && <Error message={errors.comment} />}
          >
            <Input.TextArea
              value={values.comment}
              placeholder='Комментарий'
              onChange={handleChange('comment')}
              style={{ width: 500, marginTop: 25 }}
              className={styles.answerTextarea}
              rows='6'
            />
          </Form.Item>
          <Button loading={loading} type='primary' htmlType='submit'>
            {editing.is ? 'Сохранить' : 'Добавить'}
          </Button>
          {editing.is && (
            <Button
              loading={loading}
              type='default'
              onClick={handleCancelEditing}
            >
              Отменить
            </Button>
          )}
        </Form>
      </ConditionalRender>
    </div>
  )
}
