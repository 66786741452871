import { createEmptyHistoryState } from '@lexical/react/LexicalHistoryPlugin'
import * as React from 'react'
import { createContext, ReactNode, useContext, useMemo } from 'react'

const Context = createContext({})

export const SharedHistoryContext = ({ children }: { children: ReactNode }) => {
  const historyContext = useMemo(
    () => ({ historyState: createEmptyHistoryState() }),
    []
  )
  return <Context.Provider value={historyContext}>{children}</Context.Provider>
}

export const useSharedHistoryContext = () => {
  return useContext(Context)
}
