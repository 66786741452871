import { useHistory } from 'react-router'
import { useDebouncedCallback } from 'use-debounce/lib'
import ConditionalRender from 'components/ConditionalRender'
import {
  Table,
  Button,
  Typography,
  Form,
  Input,
  Checkbox,
  Row,
  Tooltip
} from 'antd'
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import Icon from '@ant-design/icons'
import NumericInput from 'components/NumericInput'
import useStateFromQuery from 'hooks/useStateFromQuery'
import io from 'socket.io-client'

import Pagination from 'components/Pagination'
import MultiSelect from 'components/MultiSelect'
import RangePicker from 'components/RangePicker'
import StatusIndicator from 'components/StatusIndicator'
import PhoneNumberInput from 'components/PhoneNumberInput'
import { NewTicketFormModal } from './Modals'

import axios from 'utils/axios'
import {
  formatTime,
  makeColorIndicator,
  dateToApiDateFormat,
  getDateFromApiDateFormat
} from 'utils/helpers'

import telegramIcon from 'icons/telegram.svg'
import maxbonusIcon from 'icons/maxbonus.svg'
import { ReactComponent as radiationIcon } from 'icons/radiation.svg'
import { ReactComponent as vipIcon } from 'icons/vip.svg'
import { MailOutlined } from '@ant-design/icons'

import 'antd/dist/antd.css'
import styles from './styles.module.scss'

const FILTER_INITIAL = {
  search: '',
  id: '',
  clientPhone: '',
  status: [],
  ticketDateStart: null,
  ticketDateEnd: null,
  detectedDateStart: null,
  detectedDateEnd: null,
  users: [],
  newMessage: false,
  type: [],
  page: 1,
  perPage: 10,
  tags: []
}

const { Title } = Typography
const Tickets = () => {
  const history = useHistory()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useStateFromQuery(FILTER_INITIAL, [
    {
      key: 'ticketDateStart',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'ticketDateEnd',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'detectedDateStart',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'detectedDateEnd',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'users',
      cb: (i) => +i
    }
  ])
  const [pagination, setPagination] = useState({})
  const [statuses, setStatuses] = useState([])
  const [tags, setTags] = useState([])
  const [types, setTypes] = useState([])
  const [users, setUsers] = useState([])
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false)

  const getStatuses = useCallback(async () => {
    const res = await axios.get('/ticket/statuses')
    setStatuses(res.data)
  }, [])

  const getTags = useCallback(async () => {
    const res = await axios.get('/tag', {
      params: { type: 1 }
    })
    setTags(res.data)
  }, [])

  const getTypes = useCallback(async () => {
    const res = await axios.get('/ticket/types')
    setTypes([...res.data, { name: 'Тип отсутствует', id: 0 }])
  }, [])

  const getUsers = useCallback(async () => {
    const res = await axios.get('/user/staff')
    res.data && setUsers(res.data.map(({ id, name }) => ({ id, label: name })))
  }, [])

  useEffect(() => {
    if (!statuses.length) {
      getStatuses()
    }
    if (!tags.length) {
      getTags()
    }
    if (!types.length) {
      getTypes()
    }

    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsStatuses = useMemo(() => {
    return statuses.map((item) => ({ id: item.id, label: item.name }))
  }, [statuses])

  const optionsTags = useMemo(() => {
    return tags.map((item) => ({ id: item.id, label: item.name }))
  }, [tags])

  const optionsTypes = useMemo(() => {
    return types.map((item) => ({ id: item.id, label: item.name }))
  }, [types])

  const getData = useDebouncedCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/ticket', {
        params: {
          ...filter,
          ticketDateStart: dateToApiDateFormat(filter.ticketDateStart),
          ticketDateEnd: dateToApiDateFormat(filter.ticketDateEnd),
          detectedDateStart: dateToApiDateFormat(filter.detectedDateStart),
          detectedDateEnd: dateToApiDateFormat(filter.detectedDateEnd)
        }
      })

      setData(
        res.data.map((i) => {
          let colorIndicator = makeColorIndicator(i.status.name)
          return { ...i, colorIndicator }
        })
      )
      setPagination(res.pagination)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getData()
  }, [filter, getData])

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_SOCKET)
    socket.on('ticket', () => getData())
    socket.on('message', () => getData())

    return () => socket.disconnect()
  }, [getData])

  const handleChangePagination = (value) => {
    setFilter({ ...filter, ...value })
  }

  const merchantClickHandler = (id) => (ev) => {
    ev.stopPropagation()
    history.push(`/admin/merchant/${id}`)
  }

  const columns = useMemo(
    () => [
      {
        id: 'id',
        title: '№',
        dataIndex: 'id',
        key: 'id',
        render: (value, record) => (
          <div className={styles.idContainer}>
            {record.newMessage && (
              <div className={styles.idMail}>{<MailOutlined />}</div>
            )}
            <div className={styles.idSelf}>{value}</div>
          </div>
        )
      },
      {
        title: 'Стадия',
        dataIndex: 'status',
        key: 'status',
        width: '5%',
        render: (value, record) => (
          <div className={styles.statusContainer}>
            <StatusIndicator
              indicator={record.colorIndicator}
              content={value.name}
            />
            {record.systemDecisionId && (
              <Icon className={styles.problemIcon} component={radiationIcon} />
            )}
          </div>
        )
      },
      {
        title: 'Телефон источника',
        dataIndex: 'clientPhone',
        key: 'clientPhone',
        render: (value, record) => (
          <div className={styles.phoneContainer}>
            <div className={styles.clientPhone}>{record.clientPhone}</div>
            {record.merchantContactId && (
              <Tooltip
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                placement='top'
                title={record.merchantContact.name}
              >
                <Icon
                  className={styles.vipIcon}
                  component={vipIcon}
                  onClick={merchantClickHandler(record.merchantContactId)}
                />
              </Tooltip>
            )}
          </div>
        )
      },
      {
        title: 'Место проблемы',
        dataIndex: 'kiosk',
        key: 'kiosk'
      },
      {
        title: 'Дата и время обращения',
        dataIndex: 'formFilledDate',
        key: 'formFilledDate',
        render: (value) => (
          <div className={styles.date}>{formatTime(value)}</div>
        )
      },
      {
        title: 'Дата и время проблемы',
        dataIndex: 'detectedAt',
        key: 'detectedAt',
        render: (value) => (
          <div className={styles.date}>{formatTime(value)}</div>
        )
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        render: (value) => (value ? value.name : '-')
      },
      {
        title: 'Описание проблемы',
        dataIndex: 'description',
        key: 'description',
        render: (value) => <div className={styles.description}>{value}</div>
      },
      {
        title: 'Сотрудник',
        dataIndex: 'user',
        key: 'user',
        render: (value) => (value ? value.name : '-')
      },
      {
        title: '',
        dataIndex: 'channel',
        key: 'channel',
        render: (value, record) =>
          record.channel?.id === 1 ? (
            <img
              className={styles.channelIcon}
              alt='Telegram icon'
              src={telegramIcon}
            />
          ) : (
            <img
              className={styles.channelIcon}
              alt='Maxbonus icon'
              src={maxbonusIcon}
            />
          )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleClickRow = (record) => {
    return {
      onClick: () => history.push(`/admin/ticket/${record.id}`),
      onMouseDown: (e) => {
        if (e.button === 1) window.open(`/admin/ticket/${record.id}`, '_blank')
      }
    }
  }

  const handleChangePhoneNumber = (clientPhone) => {
    setFilter({ ...filter, clientPhone, page: 1 })
  }

  const handleChangeFilter = (name) => (event) => {
    setFilter({ ...filter, [name]: event.target.value, page: 1 })
  }

  const handleChangeCkeckbox = (name) => (event) => {
    setFilter({ ...filter, [name]: event.target.checked, page: 1 })
  }

  const handleChangeFilterSelect = (name) => (value) => {
    setFilter({ ...filter, [name]: value, page: 1 })
  }

  const handleChangeFilterDate = (name) => (moment) => {
    const start = moment ? moment[0] : null
    const end = moment ? moment[1] : null

    if (name === 'ticket') {
      setFilter({
        ...filter,
        ticketDateStart: start,
        ticketDateEnd: end,
        page: 1
      })
    } else if (name === 'detected') {
      setFilter({
        ...filter,
        detectedDateStart: start,
        detectedDateEnd: end,
        page: 1
      })
    }
  }

  const handleOpenNewTicketForm = () => {
    setNewTicketModalOpen(true)
  }

  return (
    <div>
      <Title className={styles.mb} level={2}>
        Список обращений
      </Title>
      <ConditionalRender roles={[1, 2]}>
        <Button
          type='primary'
          size='large'
          style={{
            marginBottom: 30
          }}
          onClick={handleOpenNewTicketForm}
        >
          Новое обращение
        </Button>
      </ConditionalRender>
      <Form
        wrapperCol={{
          span: 10
        }}
        layout='vertical'
      >
        <Row>
          <Form.Item label='Номер' className={styles.formItem}>
            <NumericInput
              value={filter.id}
              style={{ width: '100px' }}
              placeholder='№ обращения'
              onChange={handleChangeFilter('id')}
            />
          </Form.Item>

          <Form.Item label='Телефон' className={styles.formItem}>
            <PhoneNumberInput
              style={{ width: 204 }}
              value={filter.clientPhone}
              onChange={handleChangePhoneNumber}
            />
          </Form.Item>

          <Form.Item label='Поиск' className={styles.formItem}>
            <Input
              value={filter.search}
              style={{ width: '250px' }}
              placeholder='По месту или описанию проблемы'
              onChange={handleChangeFilter('search')}
            />
          </Form.Item>

          <Form.Item label='Сотрудник' className={styles.formItem}>
            <MultiSelect
              mode='multiple'
              maxTagCount={1}
              maxTagTextLength={12}
              options={users}
              style={{ width: 150 }}
              value={filter.users}
              onChange={handleChangeFilterSelect('users')}
            />
          </Form.Item>

          <Form.Item label='Дата и время обращения' className={styles.formItem}>
            <RangePicker
              value={[filter.ticketDateStart, filter.ticketDateEnd]}
              onChange={handleChangeFilterDate('ticket')}
            />
          </Form.Item>

          <Form.Item
            label='Дата и время обнаружения'
            className={styles.formItem}
          >
            <RangePicker
              value={[filter.detectedDateStart, filter.detectedDateEnd]}
              onChange={handleChangeFilterDate('detected')}
            />
          </Form.Item>

          <Form.Item label='Тип' className={styles.formItem}>
            <MultiSelect
              value={filter.type}
              mode='multiple'
              style={{ width: '200px' }}
              options={optionsTypes}
              maxTagCount={1}
              maxTagTextLength={12}
              onChange={handleChangeFilterSelect('type')}
            />
          </Form.Item>
          <Form.Item label='Стадия' className={styles.formItem}>
            <MultiSelect
              value={filter.status}
              mode='multiple'
              style={{ width: '200px' }}
              options={optionsStatuses}
              maxTagCount={1}
              maxTagTextLength={12}
              onChange={handleChangeFilterSelect('status')}
            />
          </Form.Item>

          <Form.Item label='Теги' className={styles.formItem}>
            <MultiSelect
              value={filter.tags}
              mode='multiple'
              style={{ width: '300px' }}
              options={optionsTags}
              maxTagCount={1}
              maxTagTextLength={12}
              onChange={handleChangeFilterSelect('tags')}
            />
          </Form.Item>
          <Form.Item label='Новые сообщения' className={styles.formItem}>
            <Checkbox
              checked={filter.newMessage}
              style={{ width: '200px' }}
              onChange={handleChangeCkeckbox('newMessage')}
            />
          </Form.Item>
        </Row>
      </Form>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        // locale={{ emptyText: 'Данные отсутствуют' }}
        pagination={false}
        scroll={{ x: 1200 }}
        onRow={handleClickRow}
        rowClassName={styles.row}
      />
      <NewTicketFormModal
        open={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
      />
      <Pagination onChange={handleChangePagination} pagination={pagination} />
    </div>
  )
}

export default Tickets
