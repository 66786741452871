import { useState } from 'react'
import axios from 'utils/axios'
import { useNotification } from 'hooks/useNotification'

export const useMerchant = () => {
  const [loading] = useState()

  const { createNotification } = useNotification()

  const getMerchantById = async (id) => {
    try {
      const res = await axios.get(`/merchant/${id}`)
      return res.data
    } catch (err) {
      /*empty*/
    }
  }

  const getAllMerchants = async (params) => {
    try {
      const res = await axios.get('/merchant', { params })
      return res.data
    } catch (err) {
      /*empty*/
    }
  }

  const createMerchant = async (data) => {
    try {
      const res = await axios.post('/merchant', data)
      createNotification(res.message, 'success')
      return res.data
    } catch (err) {
      /*empty*/
    }
  }

  const updateMerchant = async (id, data) => {
    try {
      const res = await axios.put(`/merchant/${id}`, data)
      createNotification(res.message, 'success')
      return res.data
    } catch (err) {
      /*empty*/
    }
  }

  const deleteMerchant = async (id) => {
    try {
      const res = await axios.delete(`/merchant/${id}`)
      createNotification(res.message, 'success')
      return res
    } catch (err) {
      /*empty*/
    }
  }

  const createMerchantContact = async (data) => {
    try {
      const res = await axios.post('/merchant/contact', data)
      createNotification(res.message, 'success')
      return res.data
    } catch (err) {
      /*empty*/
    }
  }

  const updateMerchantContact = async (id, data) => {
    try {
      const res = await axios.put(`/merchant/contact/${id}`, data)
      createNotification(res.message, 'success')
      return res.data
    } catch (err) {
      /*empty*/
    }
  }

  const deleteMerchantContact = async (id) => {
    try {
      const res = await axios.delete(`/merchant/contact/${id}`)
      createNotification(res.message, 'success')
    } catch (err) {
      /*empty*/
    }
  }

  const setKioskToMerchant = async (merchantId, kiosks) => {
    try {
      const res = await axios.put(`/merchant/${merchantId}/kiosks`, { kiosks })
      createNotification(res.message, 'success')
    } catch (err) {
      /*empty*/
    }
  }

  return {
    getMerchantById,
    createMerchant,
    updateMerchant,
    deleteMerchant,
    getAllMerchants,
    loading,
    createMerchantContact,
    updateMerchantContact,
    deleteMerchantContact,
    setKioskToMerchant
  }
}
