import locale from 'antd/es/date-picker/locale/ru_RU'
import 'moment/locale/ru'
import { DatePicker } from 'antd'
import React from 'react'
// const { RangePicker } = DatePicker

const RangePickerField = ({ className, ...res }) => {
  return (
    <DatePicker.RangePicker
      className={className}
      style={{ width: '250px' }}
      locale={locale}
      placeholder={['Начало', 'Окончание']}
      {...res}
    />
  )
}

export default RangePickerField
