import clsx from 'clsx'
import styles from './styles.module.scss'
import React from 'react'
const StatusIndicator = (props) => {
  const { indicator, className, content } = props

  return (
    <div className={clsx(styles.container, className)}>
      <div
        style={{ backgroundColor: indicator }}
        className={styles.indicator}
      ></div>
      {content}
    </div>
  )
}

export default StatusIndicator
