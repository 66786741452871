import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import ReactPaginate from 'react-paginate'
import React from 'react'
import SingleSelect from 'components/SingleSelect'

import styles from './styles.module.scss'

const PAG_OPTIONS = [
  { id: 10, label: 10 },
  { id: 20, label: 20 },
  { id: 30, label: 30 }
]

const Pagination = ({ pagination, onChange }) => {
  const handlePageChange = (page) => {
    onChange({ page: page.selected + 1 })
  }

  const handleChangeRowCount = (perPage) => {
    onChange({ page: 1, perPage })
  }

  return (
    <div className={styles.paginationContainer}>
      {pagination.pageCount > 0 && (
        <>
          <ReactPaginate
            pageCount={pagination.pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            pageClassName={styles.page}
            nextClassName={styles.next}
            breakClassName={styles.break}
            activeClassName={styles.active}
            previousClassName={styles.prev}
            disabledClassName={styles.disabled}
            containerClassName={styles.container}
            nextLabel={<ArrowRightOutlined />}
            previousLabel={<ArrowLeftOutlined />}
            forcePage={pagination.page - 1}
          />
          <div className={styles.selectContainer}>
            <SingleSelect
              options={PAG_OPTIONS}
              value={'10'}
              showArrow={false}
              className={styles.select}
              onChange={handleChangeRowCount}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Pagination
