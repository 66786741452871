import { useCallback } from 'react'
import axios from 'utils/axios'
import { useNotification } from 'hooks/useNotification'

export const useChangeReport = () => {
  const { createNotification } = useNotification()

  const getColumnOptions = useCallback(async () => {
    try {
      const res = await axios.get('/user/columns')
      return res.data
    } catch (err) {
      throw new Error(err)
    }
  }, [])

  const deleteColumnOption = useCallback(async (id) => {
    try {
      const res = await axios.delete(`/user/column/${id}`)
      createNotification(res.data.message, 'success')
    } catch (err) {
      throw new Error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveColumnOption = useCallback(async (data) => {
    try {
      const res = await axios.post('/user/column', data)
      createNotification(res.data.message, 'success')
    } catch (err) {
      throw new Error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    getColumnOptions,
    deleteColumnOption,
    saveColumnOption
  }
}
