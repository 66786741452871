import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  useMemo,
  useCallback
} from 'react'
import {
  Menu,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Space,
  Spin,
  Form,
  Select,
  Popconfirm,
  Tag
} from 'antd'
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import Editor from './Editor'
import axiosInstance from 'utils/axios'
import { useProfile } from 'hooks/useContext'
import ConditionalRender from 'components/ConditionalRender'
import { useDebouncedCallback } from 'use-debounce'
import clsx from 'clsx'
import styles from './styles.module.scss'
import MultiSelect from 'components/MultiSelect'
const Action = {
  articles: 'articles',
  filter: 'filter',
  createNew: 'createNew',
  cancelCreate: 'cancelCreate',
  openArticle: 'openArticle',
  loading: 'loading',
  isOpening: 'isOpening',
  edit: 'edit',
  createdNewArticle: 'createdNewArticle',
  isDeleting: 'isDeleting',
  deleted: 'deleted',
  tags: 'tags'
}

const HEADLINE_DELIM = '@@'

const { CheckableTag } = Tag

const action = (type, payload) => ({ type, payload })

function dataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',')
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

const reducer = (state, action) => {
  switch (action.type) {
    case Action.articles: {
      const articles = action.payload
      return {
        ...state,
        articles,
        filteredArticles: articles,
        isCreatingNew: false,
        filterInput: '',
        isEditing: false,
        isLoading: false
      }
    }

    case Action.filter: {
      const input = action.payload.trim().toLowerCase()

      const filteredArticles =
        input !== ''
          ? state.articles.filter((i) => i.title.toLowerCase().includes(input))
          : state.articles

      return { ...state, filteredArticles, filterInput: action.payload }
    }

    case Action.createNew: {
      return { ...state, isCreatingNew: true }
    }

    case Action.createdNewArticle: {
      const { articles, newArticle } = action.payload
      return {
        ...state,
        isCreatingNew: false,
        isLoading: false,
        articles,
        filteredArticles: articles,
        openedArticle: newArticle,
        isEditing: false,
        isSaving: false,
        filterInput: ''
      }
    }

    case Action.cancelCreate: {
      return {
        ...state,
        openedArticle: {
          ...state.openedArticle,
          editorState: { ...state.openedArticle.editorState }
        },
        isCreatingNew: false,
        isEditing: false
      }
    }

    case Action.openArticle: {
      return {
        ...state,
        isLoading: false,
        isEditing: false,
        isSaving: false,
        isCreatingNew: false,
        openedArticle: action.payload,
        isOpening: false
      }
    }

    case Action.loading: {
      const isLoading = action.payload
      const isSaving = (state.isCreatingNew || state.isEditing) && isLoading
      return { ...state, isLoading, isSaving, isOpening: false }
    }

    case Action.isOpening: {
      const isOpening = action.payload
      return { ...state, isOpening }
    }

    case Action.edit: {
      const isEditing = action.payload
      return {
        ...state,
        isEditing
      }
    }

    case Action.isDeleting: {
      const isDeleting = action.payload
      return { ...state, isDeleting }
    }

    case Action.deleted: {
      const articles = action.payload
      return {
        ...state,
        articles,
        filteredArticles: articles,
        isDeleting: false,
        openedArticle: null,
        isEditing: false
      }
    }

    case Action.tags: {
      return {
        ...state,
        tags: action.payload
      }
    }
  }
}

export default function KnowledgeBase() {
  const { isAdmin } = useProfile().user

  const editorRef = useRef()

  const [state, dispatch] = useReducer(reducer, {
    articles: [],
    filteredArticles: [],
    openedArticle: null,
    isCreatingNew: false,
    filterInput: '',
    isLoading: true,
    isEditing: false,
    isSaving: false,
    isDeleting: false,
    isOpening: '',
    tags: []
  })
  const [searchSuggestions, setSearchSuggestions] = useState([])
  const [isSearching, setSearching] = useState(false)

  const handleSearch = useDebouncedCallback(async (value) => {
    value = value.trim()
    if (!value) return setSearchSuggestions([])

    setSearching(true)
    try {
      const { foundArticles } = (await axiosInstance.get('/article?q=' + value))
        .data
      const suggestions = foundArticles.map(({ id, title, headline }) => {
        const split = headline.split(HEADLINE_DELIM)
        if (split.length !== 1) {
          headline = (
            <>
              ...{split[0]}
              <Typography.Text strong mark>
                {split[1]}
              </Typography.Text>
              {split[2]}...
            </>
          )
        }

        return (
          <Select.Option key={id} value={id} style={{ marginBottom: 14 }}>
            <Typography.Title level={4}>{title}</Typography.Title>
            {headline}
          </Select.Option>
        )
      })
      setSearchSuggestions(suggestions)
    } catch {
      /** */
    } finally {
      setSearching(false)
    }
  }, 666)

  const handleSelectSuggestion = async (key) => {
    setSearchSuggestions([])

    try {
      const article = (await axiosInstance.get(`/article/${key}`)).data
      dispatch(action(Action.openArticle, article))
    } catch {
      /** */
    }
  }

  const mapOptionsTags = (tags) => {
    return tags.map((item) => ({
      id: item.id,
      label: item.name
    }))
  }

  useEffect(() => {
    const f = async () => {
      try {
        const { articles } = (await axiosInstance.get('/article')).data
        dispatch(action(Action.articles, articles))

        if (articles.length > 0) {
          const firstArticle = (
            await axiosInstance.get(`/article/${articles[0].id}`)
          ).data

          const tags = (
            await axiosInstance.get(`/tag`, {
              params: { type: 2 }
            })
          ).data

          const optionsTags = mapOptionsTags(tags)

          dispatch(action(Action.tags, optionsTags))

          dispatch(action(Action.openArticle, firstArticle))
        }
      } catch {
        /** */
      }
    }

    f()
  }, [])

  const handleSave = async (values) => {
    dispatch(action(Action.loading, true))

    const title = values.title.trim()
    const tags = values.tags || []

    const { openedArticle } = state
    const editorState = editorRef.current.getState()
    const fd = new FormData()

    // Поиск картинок в стейте
    let count = 0
    for (const el of editorState.root.children) {
      if (Array.isArray(el.children)) {
        for (const currNode of el.children) {
          const { type, src } = currNode

          if (
            type === 'image' &&
            typeof src === 'string' &&
            src.startsWith('data:')
          ) {
            const blob = dataURIToBlob(src)
            const name = `${count}.${blob.type.split('/')[1]}`
            fd.append('images', blob, name)
            currNode.src = '$' + count++
          }
        }
      }
    }

    fd.set('title', title)
    fd.set('tags', JSON.stringify(tags))
    fd.set('editorState', JSON.stringify(editorState))

    try {
      if (state.isCreatingNew) {
        const res = (await axiosInstance.post('/article', fd)).data
        dispatch(action(Action.createdNewArticle, res))
      } else if (state.isEditing) {
        const article = (
          await axiosInstance.put(`/article/${openedArticle.id}`, fd)
        ).data
        dispatch(action(Action.openArticle, article))
      }
    } catch {
      dispatch(action(Action.loading, false))
    }
  }

  const handleFilterArticles = (event) => {
    dispatch(action(Action.filter, event.target.value))
  }

  const handleArticleClick = async ({ key }) => {
    const article = state.articles.find((a) => a.id === +key)
    if (state.openedArticle?.id === article.id) return

    dispatch(action(Action.isOpening, article.title))
    try {
      const article = (await axiosInstance.get(`/article/${key}`)).data
      dispatch(action(Action.openArticle, article))
    } catch (error) {
      dispatch(action(Action.loading, false))
    }
  }

  const handleCreateNewArticle = () => {
    editorRef.current?.clear()
    dispatch(action(Action.createNew))
  }

  const handleDeleteArticle = async () => {
    dispatch(action(Action.isDeleting, true))

    try {
      const articles = (
        await axiosInstance.delete('/article/' + state.openedArticle.id)
      ).data
      dispatch(action(Action.deleted, articles))
    } catch {
      dispatch(action(Action.isDeleting, false))
    }
  }

  const handleFilterTags = async (values) => {
    setSearching(true)

    let params = {}
    const find = values.includes(0)

    if (!find) {
      params.tags = JSON.stringify(values)
    }

    try {
      const { articles } = (await axiosInstance.get('/article', { params }))
        .data

      dispatch(action(Action.articles, articles))
    } catch {
      /** */
    } finally {
      setSearching(false)
    }
  }
  const firstTag = { id: 0, label: 'Все теги' }
  const [selectedTags, setSelectedTags] = useState([firstTag])

  const handleSelectedArticleTag = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t.id !== tag.id)

    handleFilterTags(nextSelectedTags.map(({ id }) => id))
    setSelectedTags(nextSelectedTags)
  }

  const INPUTS_DISABLED =
    state.isLoading ||
    state.isEditing ||
    state.isCreatingNew ||
    state.isOpening ||
    state.isDeleting

  const EDITOR_READONLY =
    !isAdmin ||
    (isAdmin && !state.isCreatingNew && !state.isEditing) ||
    state.isSaving

  return (
    <div className={styles.container}>
      <Row className={clsx(styles.tags, INPUTS_DISABLED && styles.disabled)}>
        {[firstTag, ...state.tags].map((tag) => {
          return (
            <CheckableTag
              key={tag.id}
              checked={selectedTags.some((obj) => obj.id === tag.id)}
              onChange={(checked) => handleSelectedArticleTag(tag, checked)}
            >
              {tag.label}
            </CheckableTag>
          )
        })}
      </Row>
      <Select
        showSearch
        className={styles.search}
        placeholder='Поиск по базе знаний'
        size='large'
        onSearch={handleSearch}
        filterOption={false}
        loading={isSearching}
        onSelect={handleSelectSuggestion}
        notFoundContent={isSearching ? <Spin /> : null}
        value={[]}
      >
        {searchSuggestions}
      </Select>
      <div className={styles.content}>
        <div className={styles.listWrapper}>
          <Input
            value={state.filterInput}
            onChange={handleFilterArticles}
            placeholder='Поиск по статьям'
            bordered={false}
            className={styles.listSearch}
            prefix={<SearchOutlined />}
            disabled={state?.articles?.length === 0 || INPUTS_DISABLED}
            style={{ borderRight: '1px solid #f0f0f0' }}
          />
          <Menu
            className={styles.list}
            disabled={INPUTS_DISABLED}
            selectedKeys={[state.openedArticle?.id + '']}
          >
            {state.filteredArticles.map((i) => (
              <Menu.Item
                title={i.title}
                key={i.id}
                onClick={handleArticleClick}
              >
                {i.title}
              </Menu.Item>
            ))}
          </Menu>
          <Button
            type='dashed'
            icon={<PlusOutlined />}
            onClick={handleCreateNewArticle}
            className={styles.addArticleButton}
            disabled={INPUTS_DISABLED}
          >
            Добавить новую статью
          </Button>
        </div>
        <div
          className={clsx(
            styles.editorWrapper,
            EDITOR_READONLY && styles.editorWrapperReadOnly
          )}
        >
          {(state.openedArticle || state.isCreatingNew) && (
            <Space
              direction='vertical'
              size='middle'
              className={styles.editorWrapperSpacer}
            >
              {state.isCreatingNew || state.isEditing ? (
                <Form
                  initialValues={{
                    title: state.isCreatingNew ? '' : state.openedArticle?.title
                  }}
                  onFinish={handleSave}
                >
                  <Row>
                    <Form.Item
                      name='tags'
                      label={'Теги'}
                      style={{ flexBasis: '78%' }}
                    >
                      <MultiSelect
                        options={state.tags}
                        placeholder='Выбрать тег'
                        mode='multiple'
                        defaultValue={state.openedArticle?.tags?.map(
                          ({ id }) => id
                        ) || []}
                      />
                    </Form.Item>
                  </Row>
                  <Row className={styles.mainRow}>
                    <Form.Item
                      name='title'
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Введите название статьи'
                        }
                      ]}
                      style={{ flexBasis: '78%' }}
                    >
                      <Input
                        placeholder='Название статьи'
                        disabled={state.isSaving || state.isDeleting}
                      />
                    </Form.Item>
                    <Space>
                      <Form.Item>
                        <Button
                          type='primary'
                          htmlType='submit'
                          loading={state.isSaving}
                        >
                          Сохранить
                        </Button>
                      </Form.Item>
                      {state.isEditing && !state.isCreatingNew && (
                        <Form.Item>
                          <Popconfirm
                            title='Вы уверены, что хотите удалить статью?'
                            okText='Да'
                            cancelText='Нет'
                            placement='topLeft'
                            onConfirm={handleDeleteArticle}
                          >
                            <Button danger loading={state.isDeleting}>
                              Удалить
                            </Button>
                          </Popconfirm>
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          onClick={() => dispatch(action(Action.cancelCreate))}
                          disabled={state.isSaving || state.isDeleting}
                        >
                          Отмена
                        </Button>
                      </Form.Item>
                    </Space>
                  </Row>
                </Form>
              ) : (
                <Row align='middle' justify='space-between'>
                  <Col span={20}>
                    <Typography.Title
                      className={styles.articleTitle}
                      title={state.isOpening || state.openedArticle.title}
                    >
                      {state.isOpening || state.openedArticle.title}
                    </Typography.Title>
                  </Col>
                  {!state.isOpening && (
                    <ConditionalRender roles={[1]}>
                      <Col
                        span={4}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => dispatch(action(Action.edit, true))}
                        >
                          Редактировать
                        </Button>
                      </Col>
                    </ConditionalRender>
                  )}
                </Row>
              )}
              {state.isOpening ? (
                <div className={styles.spinnerContainer}>
                  <Spin size='large' />
                </div>
              ) : (
                <Editor
                  ref={editorRef}
                  initialState={
                    state.isCreatingNew
                      ? null
                      : state.openedArticle?.editorState
                  }
                  readOnly={EDITOR_READONLY}
                />
              )}
            </Space>
          )}
        </div>
      </div>
    </div>
  )
}
