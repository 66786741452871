import { useCallback, useState } from "react"
import axios from 'utils/axios'

export const useMessage = () => {
  const [loading, setLoading] = useState()

  const getTicketMessages = useCallback(async (id) => {
    try {
      const res = await axios.get(`/message/${id}`)
      return res.data
    }
    catch (e) {
      console.log(e)
    }
  }, [])

  const sendMessage = useCallback(async (data) => {
    try {
      setLoading(true)
      const res = await axios.post('/message', data)
      return res.data
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setLoading(false)
    }
  }, [])

  const messageRead = useCallback(async (id) => {
    try {
      await axios.put(`/message/read/${id}`)
    }
    catch (e) {
      console.log(e)
    }
  }, [])

  return { getTicketMessages, sendMessage, messageRead, loading }
}