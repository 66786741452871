import XLSX from 'xlsx'
import moment from 'moment'
import FileSaver from 'file-saver'
import { Button, Form, Row, Checkbox, Table } from 'antd'
import { TableSelect } from 'components/TableSelect'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { UploadReportEmailModal } from './modals/UploadReportEmailModal'
import RangePicker from 'components/RangePicker'
import {
  pColumns,
  initialColumns,
  problemInitialColumns,
  initialSupportCilumns,
  mColumns,
  tColumns,
  rColumns,
  systemProblemColumns
} from './atoms/Columns'
import { Options } from './atoms/OptionsList'

import axios from 'utils/axios'
import { useChangeReport } from 'api/Report'
import { useStorage } from 'hooks/useContext'
import {
  formatTime,
  convertMinsToHrsMins,
  getUserName,
  getAllDatesPerStatus,
  getDeltaInHrsMins,
  minDate,
  dateToApiDateFormat
} from 'utils/helpers'

import SingleSelect from 'components/SingleSelect'
import styles from './styles.module.scss'
import './colors.scss'
import 'antd/dist/antd.css'

const INITIAL_FILTER = {
  ticketDateStart: null,
  ticketDateEnd: null,
  closed: false
}

const COLUMNS = { ...initialColumns }
const PROBLEM_COLUMNS = { ...problemInitialColumns }
const SUPPORT_COLUMNS = { ...initialSupportCilumns }

const countDecisionDeltaBetweenDate = (hist) => {
  /* Время затраченное на решение по обращению */
  const ddingTemporaryAppeal = hist.find(
    (item) => item.eventId === 7 || item.eventId === 8
  )?.createdAt

  const findIndexCut = hist.findIndex(
    (item) => item.eventId === 7 || item.eventId === 8
  )

  const addingCaseAnalysis = hist
    .slice(findIndexCut)
    .find((item) => item.eventId === 6 || item.eventId === 5)?.createdAt

  return ddingTemporaryAppeal && addingCaseAnalysis
    ? getDeltaInHrsMins(ddingTemporaryAppeal, addingCaseAnalysis)
    : false
}

const Reports = () => {
  const { saveColumnOption, getColumnOptions } = useChangeReport()

  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ ...INITIAL_FILTER })
  const [selectedColumns, setSelectedColumns] = useState(Object.keys(COLUMNS))
  const [selectedProblemColumns, setSelectedProblemColumns] = useState(
    Object.keys(PROBLEM_COLUMNS)
  )
  const [report, setReport] = useState(1)
  const [data, setData] = useState([])
  const [problemData, setProblemData] = useState([])
  const [optionsList, setOptionsList] = useState([])
  const [currentTableData, setCurrentTableData] = useState([])
  const [currentProblemTableData, setCurrentProblemTableData] = useState([])

  const [openUploadReportEmailModal, setUploadReportEmailModal] =
    useState(false)

  const [supportReportData, setSupportReportData] = useState([])
  const [currentSupportReportData, setCurrentSupportReportData] = useState([])

  const [systemProblemData, setSystemProblemData] = useState([])
  const [currentSystemProblemTableData, setCurrentSystemProblemTableData] =
    useState([])

  const {
    staff,
    loadStaff,
    channels,
    loadChannels,
    problemPlaces,
    loadProblemPlaces,
    merchants,
    loadMerchants,
    softwares,
    loadSoftwares,
    problemUsers,
    loadProblemUsers
  } = useStorage()

  // Get all data for filter "ticket table"
  useEffect(() => {
    loadStaff()
  }, [loadStaff])

  useEffect(() => {
    loadChannels()
  }, [loadChannels])

  useEffect(() => {
    loadProblemPlaces()
  }, [loadProblemPlaces])

  useEffect(() => {
    loadMerchants()
  }, [loadMerchants])

  useEffect(() => {
    loadSoftwares()
  }, [loadSoftwares])

  useEffect(() => {
    loadProblemUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOptionsList = useCallback(async () => {
    const res = await getColumnOptions()
    setOptionsList(res)
  }, [getColumnOptions])

  useEffect(() => {
    getOptionsList()
  }, [getOptionsList])

  // return new object to map data from database to table columns
  const makeTableData = (data) => {
    const allAnalysDate = getAllDatesPerStatus(
      data.ticketStatus,
      'Анализ обращения'
    )
    const allDecisionDate = getAllDatesPerStatus(
      data.ticketStatus,
      'Временное решение'
    )

    const analysDate = allAnalysDate ? minDate(allAnalysDate) : null
    const decisionDate = allDecisionDate ? minDate(allDecisionDate) : null

    /* Добавления анализа обращения */
    const addingCaseAnalysis = data.histories.find((item) => item.id === 5)
      ?.History.createdAt

    /* Добавления временого обращения */
    const ddingTemporaryAppeal = data.histories.find((item) => item.id === 7)
      ?.History.createdAt

    const analysStartedAt = analysDate ? formatTime(analysDate) : undefined
    const decisionStartedAt = decisionDate ? formatTime(decisionDate) : '-'
    return {
      id: data.id,
      currentStatus: data.status?.name,
      channel: data.channel?.name,
      clientName: data.clientName,
      clientEmail: data.clientEmail,
      clientPhone: data.clientPhone,
      problemPlace: data.problemPlace?.name,
      truthKiosk: data.truthKiosk
        ? `${data.problemPlace?.name} - ${data.truthKiosk}`
        : data.kiosk
        ? `${data.problemPlace?.name} - ${data.kiosk}`
        : data.problemPlace?.name,
      merchant: data.merchants.length
        ? data.merchants.map((item) => item.name).join(', ')
        : '',
      createdAt: formatTime(data.createdAt),
      detectedAt: formatTime(data.detectedAt) || '-',
      description: data.description,
      analysUser: getUserName(data.ticketStatus, 'Анализ обращения'),
      startWorkDelta: analysDate
        ? getDeltaInHrsMins(analysDate, data.createdAt)
        : '-',
      analysStartedAt,
      type: data.type?.name,
      software: data?.softwares.map((item) => item?.name).join(', '),
      analys: data.comment,
      // analysDelta:
      //   decisionDate && analysDate
      //     ? getDeltaInHrsMins(analysDate, decisionDate)
      //     : '-',
      analysDelta:
        addingCaseAnalysis && analysDate
          ? getDeltaInHrsMins(analysDate, addingCaseAnalysis)
          : '-',
      decisionUser: getUserName(data.ticketStatus, 'Временное решение'),
      tempDecision: data.tempDecision,
      decisionStartedAt,
      // decisionDelta: data.closedAt
      //   ? getDeltaInHrsMins(decisionDate, data.closedAt)
      //   : '-',
      decisionDelta:
        addingCaseAnalysis && ddingTemporaryAppeal
          ? getDeltaInHrsMins(addingCaseAnalysis, ddingTemporaryAppeal)
          : '-',

      systemDecisionStart: data.systemDecision?.createdAt
        ? formatTime(data.systemDecision?.createdAt)
        : '-',
      ticketCount: data.systemDecision?.ticket
        ? data.systemDecision?.ticket?.length
        : '-',
      userControl: data.systemDecision?.user?.name || '-',
      userSystemDecision: data.systemDecision?.responsible?.name || '-',
      systemDecisionStatus: data.systemDecision?.status?.name,
      systemDecisionDelta: data.systemDecision?.closedAt
        ? getDeltaInHrsMins(
            data.systemDecision?.createdAt,
            data.systemDecision?.closedAt
          )
        : '-',
      systemDecisionDescription: data.systemDecision?.description,
      systemDecisionDecision: data.systemDecision?.decision,
      productionTime: data.systemDecision?.productionTime
        ? formatTime(data.systemDecision?.productionTime)
        : '-'
    }
  }

  const makeProblemTableData = (data) => {
    return {
      id: data.id,
      name: data.name,
      createdAt: formatTime(data.createdAt),
      creator: data.creator?.name || '-',
      ticketCount: data.ticket?.length,
      description: data.description || '-',
      startedAt: data.startedWorkAt ? formatTime(data.startedWorkAt) : '-',
      userTaken: data.user?.name,
      deltaTaken: data.startedWorkAt
        ? getDeltaInHrsMins(data.createdAt, data.startedWorkAt)
        : '-',
      status: data.status?.name || '-',
      responsible: data.responsible?.name || '-',
      deltaClose: data.closedAt
        ? getDeltaInHrsMins(data.startedWorkAt, data.closedAt)
        : '-',
      decision: data.decision || '-',
      productionTime: data.productionTime
        ? formatTime(data.productionTime)
        : '-'
    }
  }

  const makeSupportReportTableData = (data) => {
    const allAnalysDate = getAllDatesPerStatus(
      data.ticketStatus,
      'Анализ обращения'
    )
    const allDecisionDate = getAllDatesPerStatus(
      data.ticketStatus,
      'Временное решение'
    )

    const analysDate = allAnalysDate ? minDate(allAnalysDate) : null
    const decisionDate = allDecisionDate ? minDate(allDecisionDate) : null

    /* Добавления анализа обращения */
    const addingCaseAnalysis = data.histories.find(
      (item) => item.eventId === 6 || item.eventId === 5
    )?.createdAt

    /* Время затраченное на решение по обращению */
    const ddingTemporaryAppeal = data.histories.find(
      (item) => item.eventId === 7 || item.eventId === 8
    )?.createdAt

    /* Добавления временого обращения */
    const userLastEditAnalys = data.histories.find(
      (item) => item.eventId === 6 || item.eventId === 5
    )?.user.name

    /* Сотрудник, выполнивший временное решение */
    const userLastEditTemporaryAppeal = data.histories.find(
      (item) => item.eventId === 7 || item.eventId === 8
    )?.user.name

    const analysStartedAt = analysDate ? formatTime(analysDate) : undefined
    const decisionStartedAt = decisionDate ? formatTime(decisionDate) : '-'

    return {
      id: data.id,
      currentStatus: data.status?.name,
      channel: data.channel?.name,
      clientName: data.clientName,
      clientEmail: data.clientEmail,
      clientPhone: data.clientPhone,
      problemPlace: data?.kiosk ? data.kiosk : '-',
      truthKiosk: data.truthKiosk
        ? `${data.problemPlace?.name} - ${data.truthKiosk}`
        : data.kiosk
        ? `${data.kiosk}`
        : data.problemPlace?.name,
      merchant: data.merchants.length
        ? data.merchants.map((item) => item.name).join(', ')
        : '',
      createdAt: formatTime(data.createdAt),
      detectedAt: formatTime(data.detectedAt) || '-',
      description: data.description,
      analysUser: userLastEditAnalys,
      startWorkDelta: analysDate
        ? getDeltaInHrsMins(analysDate, data.createdAt)
        : '-',
      analysStartedAt,
      type: data.type?.name,
      software: data?.softwares.map((item) => item?.name).join(', '),
      analys: data.comment,

      analysDelta:
        addingCaseAnalysis && analysDate
          ? getDeltaInHrsMins(analysDate, addingCaseAnalysis)
          : '-',
      decisionUser: userLastEditTemporaryAppeal,
      userClosedTicket: getUserName(data.ticketStatus, 'Закрыто'),
      tempDecision: data.tempDecision,
      tags: data.tags.map((tag) => tag.name).join(', '),
      decisionStartedAt,
      startedAt: data.startedWorkAt ? formatTime(data.startedWorkAt) : '-',
      // decisionDelta: data.closedAt
      //   ? getDeltaInHrsMins(decisionDate, data.closedAt)
      //   : '-',
      analysTime:
        addingCaseAnalysis && analysDate
          ? getDeltaInHrsMins(addingCaseAnalysis, analysDate)
          : '-',
      decisionDelta: countDecisionDeltaBetweenDate(data.histories) || '-',
      systemDecisionStart: data?.systemDecision?.startedWorkAt
        ? formatTime(data.systemDecision.startedWorkAt)
        : '-',
      systemDecisionCreate: data.systemDecision?.createdAt
        ? formatTime(data.systemDecision?.createdAt)
        : '-',
      ticketCount: data.systemDecision?.ticket
        ? data.systemDecision?.ticket?.length
        : '-',
      creator: data?.systemDecision?.creator?.name || '-',
      userControl: data.systemDecision?.user?.name || '-',
      userSystemDecision: data.systemDecision?.responsible?.name || '-',
      deltaTaken:
        data?.systemDecision?.startedWorkAt && data?.systemDecision?.createdAt
          ? getDeltaInHrsMins(
              data?.systemDecision?.startedWorkAt,
              data.systemDecision?.createdAt
            )
          : '-', // Время взятия в работу системной проблемы - Время регистрации системной проблемы

      systemDecisionStatus: data.systemDecision?.status?.name,
      systemDecisionDelta: data.systemDecision?.closedAt
        ? getDeltaInHrsMins(
            data.systemDecision?.createdAt,
            data.systemDecision?.closedAt
          )
        : '-',
      systemDecisionDescription: data.systemDecision?.description,
      systemDecisionDecision: data.systemDecision?.decision,
      productionTime: data.systemDecision?.productionTime
        ? formatTime(data.systemDecision?.productionTime)
        : '-',
      deltaClose:
        data.systemDecision?.productionTime && data.systemDecision?.createdAt
          ? getDeltaInHrsMins(
              moment(data.systemDecision?.productionTime).toISOString(),
              data.systemDecision?.createdAt
            )
          : '-'
    }
  }

  const makeSystemProblemTableData = (data) => {
    return {
      id: data.id,
      name: data.name,
      createdAt: formatTime(data.createdAt),
      creator: data.creator?.name || '-',
      ticketCount: data.ticket?.length,
      description: data.description || '-',
      startedAt: data.startedWorkAt ? formatTime(data.startedWorkAt) : '-',
      userTaken: data.user?.name,
      deltaTaken: data.startedWorkAt
        ? getDeltaInHrsMins(data.createdAt, data.startedWorkAt)
        : '-',
      status: data.status?.name || '-',
      responsible: data.responsible?.name || '-',
      deltaClose: data.closedAt
        ? getDeltaInHrsMins(data.startedWorkAt, data.closedAt)
        : '-',
      decision: data.decision || '-',
      productionTime: data.productionTime
        ? formatTime(data.productionTime)
        : '-'
    }
  }

  // return table columns for Table tag prop (columns)
  const tableColumns = useMemo(() => {
    return tColumns({
      channels,
      softwares,
      problemPlaces,
      merchants,
      staff,
      problemUsers
    })
      .filter((column) => selectedColumns.includes(column.dataIndex))
      .sort(
        (a, b) =>
          selectedColumns.indexOf(a.dataIndex) -
          selectedColumns.indexOf(b.dataIndex)
      )
  }, [
    selectedColumns,
    channels,
    problemPlaces,
    staff,
    merchants,
    softwares,
    problemUsers
  ])

  const problemTableColumns = useMemo(() => {
    return pColumns({
      channels,
      softwares,
      problemPlaces,
      merchants,
      staff,
      problemUsers
    })
      .filter((column) => selectedProblemColumns.includes(column.dataIndex))
      .sort(
        (a, b) =>
          selectedProblemColumns.indexOf(a.dataIndex) -
          selectedProblemColumns.indexOf(b.dataIndex)
      )
  }, [
    selectedProblemColumns,
    channels,
    problemPlaces,
    staff,
    merchants,
    softwares,
    problemUsers
  ])

  const supportReportTableColumns = useMemo(() => {
    return rColumns({
      channels,
      softwares,
      problemPlaces,
      merchants,
      staff,
      problemUsers
    })
  }, [
    selectedColumns,
    channels,
    problemPlaces,
    staff,
    merchants,
    softwares,
    problemUsers
  ])

  const systemProblemTablecolumn = useMemo(() => {
    return systemProblemColumns({
      channels,
      softwares,
      problemPlaces,
      merchants,
      staff,
      problemUsers
    })
  }, [
    selectedProblemColumns,
    channels,
    problemPlaces,
    staff,
    merchants,
    softwares,
    problemUsers
  ])

  const merchantColumns = useMemo(() => mColumns, [])

  const optionsTypes = [
    {
      id: 1,
      label: 'Отчет по обращениям'
    },
    {
      id: 2,
      label: 'Отчет по системным проблемам'
    },
    {
      id: 3,
      label: 'Отчет по мерчанту'
    },
    {
      id: 4,
      label: 'Отчет по техподдержке 2.0'
    }
  ]

  const exportToCSV = (csvData, fileName) => {
    const fitToColumn = (data) => {
      const columnWidths = []
      for (const property in data[0]) {
        columnWidths.push({
          wch: Math.max(
            property ? property.toString().length : 0,
            ...data.map((obj) =>
              obj[property] ? obj[property].toString().length : 0
            )
          )
        })
      }
      return columnWidths
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(csvData)
    ws['!cols'] = fitToColumn(csvData)

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    const fullFileName = `${fileName} от ${moment().format('DD.MM.YYYY')}`
    FileSaver.saveAs(data, fullFileName + fileExtension)
  }

  // const exportJSON = (csvData, fileName = 'Hello') => {
  //   const fitToColumn = (data) => {
  //     const columnWidths = []
  //     for (const property in data[0]) {
  //       columnWidths.push({
  //         wch: Math.max(
  //           property ? property.toString().length : 0,
  //           ...data.map((obj) =>
  //             obj[property] ? obj[property].toString().length : 0
  //           )
  //         )
  //       })
  //     }
  //     return columnWidths
  //   }

  //   const fileType =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   const fileExtension = '.xlsx'
  //   const Heading = [
  //     [
  //       '',
  //       '',
  //       'Пользователь',
  //       '',
  //       'Прием обращения',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       'Анализ обращения',
  //       '',
  //       '',
  //       '',
  //       '',
  //       'Временное решение по обращению',
  //       '',
  //       '',
  //       '',
  //       '',
  //       'Системная проблема'
  //     ]
  //   ]

  //   const wb = XLSX.utils.book_new()

  //   const ws = XLSX.utils.json_to_sheet(csvData, {
  //     origin: 'A2'
  //   })

  //   XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' })

  //   // // appending sheet with a name
  //   XLSX.utils.book_append_sheet(wb, ws, 'Обращения')

  //   // Here s = start, r = row, c=col, e= end
  //   const merge = [
  //     { s: { r: 0, c: 2 }, e: { r: 0, c: 3 } },
  //     { s: { r: 0, c: 4 }, e: { r: 0, c: 10 } },
  //     { s: { r: 0, c: 11 }, e: { r: 0, c: 15 } },
  //     { s: { r: 0, c: 16 }, e: { r: 0, c: 20 } },
  //     { s: { r: 0, c: 21 }, e: { r: 0, c: 30 } }
  //   ]

  //   ws['!merges'] = merge
  //   ws['!cols'] = fitToColumn(csvData)

  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  //   const data = new Blob([excelBuffer], { type: fileType })
  //   const fullFileName = `${fileName} от ${moment().format('DD.MM.YYYY')}`
  //   FileSaver.saveAs(data, fullFileName + fileExtension)
  // }

  const getProp = useCallback((object, keys, defaultVal) => {
    keys = Array.isArray(keys) ? keys : keys.split('.')
    object = object[keys[0]]
    if (object && keys.length > 1) {
      return getProp(object, keys.slice(1))
    }
    return object === undefined ? defaultVal : object
  }, [])

  const tableDataForExport = useCallback(
    (data) => {
      const thisColumns = () => {
        if (report === 1) {
          return tableColumns.filter((column) =>
            selectedColumns.includes(column.dataIndex)
          )
        }

        if (report === 4) {
          return supportReportTableColumns
        }

        return problemTableColumns.filter((column) =>
          selectedProblemColumns.includes(column.dataIndex)
        )
      }

      let dataForExport = []
      let temp = []
      const headers = thisColumns().map((item) => item.title)

      data.forEach((row) => {
        temp.push(
          thisColumns().map((col) => {
            let val = ''
            val = getProp(row, col.dataIndex)
            return val
          })
        )
      })

      temp.forEach((item) => {
        dataForExport.push(
          Object.assign(...headers.map((k, i) => ({ [k]: item[i] })))
        )
      })

      return dataForExport
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedColumns, selectedProblemColumns, report]
  )

  const dataForExportMerachant = useCallback(
    (data) => {
      const currentColumns = merchantColumns
      let dataForExport = []
      let temp = []
      const headers = currentColumns.map((item) => item.title)

      data.forEach((row) => {
        let merch = ''
        merch = row.name
        let softwareString = ''

        const probTemp = { time: 0, count: 0, timeCount: 0, softwares: [] }
        const reqTemp = { time: 0, count: 0, timeCount: 0, softwares: [] }
        const consTemp = { time: 0, count: 0, timeCount: 0, softwares: [] }

        for (let i = 0; i < row?.tickets?.length; i++) {
          if (row?.tickets[i]?.type?.name === 'Проблема') {
            probTemp.count++
            if (row?.tickets[i]?.closedAt && row?.tickets[i]?.startedWorkAt) {
              const diff =
                new Date(row?.tickets[i]?.closedAt).getTime() -
                new Date(row?.tickets[i]?.startedWorkAt).getTime()
              probTemp.time += Math.floor(diff / (1000 * 60))
              probTemp.timeCount++
            }
            for (let j = 0; j < row?.tickets[i].softwares?.length; j++) {
              if (
                probTemp.softwares.includes(row?.tickets[i].softwares[j].id)
              ) {
                probTemp[`soft${row?.tickets[i].softwares[j].id}`].count++
              } else {
                probTemp.softwares.push(row?.tickets[i].softwares[j].id)
                probTemp[`soft${row?.tickets[i].softwares[j].id}`] = {
                  name: row?.tickets[i].softwares[j].name,
                  count: 1
                }
              }
            }
          } else if (row?.tickets[i]?.type?.name === 'Запрос на улучшение') {
            reqTemp.count++
            if (row?.tickets[i]?.closedAt && row?.tickets[i]?.startedWorkAt) {
              const diff =
                new Date(row?.tickets[i]?.closedAt).getTime() -
                new Date(row?.tickets[i]?.startedWorkAt).getTime()
              reqTemp.time += Math.floor(diff / (1000 * 60))
              reqTemp.timeCount++
            }
          } else if (row?.tickets[i]?.type?.name === 'Консультация') {
            consTemp.count++
            if (row?.tickets[i]?.closedAt && row?.tickets[i]?.startedWorkAt) {
              const diff =
                new Date(row?.tickets[i]?.closedAt).getTime() -
                new Date(row?.tickets[i]?.startedWorkAt).getTime()
              consTemp.time += Math.floor(diff / (1000 * 60))
              consTemp.timeCount++
            }
          }
        }

        probTemp.softwares.forEach((item) => {
          softwareString += `${probTemp[`soft${item}`].name} - ${
            probTemp[`soft${item}`].count
          }, `
        })

        return temp.push(
          [
            merch,
            'Проблема',
            probTemp.count,
            probTemp.timeCount !== 0
              ? convertMinsToHrsMins(
                  Math.floor(probTemp.time / probTemp.timeCount)
                )
              : 0,
            softwareString
          ],
          [
            '',
            'Запрос на улучшение',
            reqTemp.count,
            reqTemp.timeCount !== 0
              ? convertMinsToHrsMins(
                  Math.floor(reqTemp.time / reqTemp.timeCount)
                )
              : 0,
            '-'
          ],
          [
            '',
            'Консультация',
            consTemp.count,
            consTemp.timeCount !== 0
              ? convertMinsToHrsMins(
                  Math.floor(consTemp.time / consTemp.timeCount)
                )
              : 0,
            '-'
          ]
        )
      })

      temp.forEach((item) => {
        dataForExport.push(
          Object.assign(...headers.map((k, i) => ({ [k]: item[i] })))
        )
      })

      return dataForExport
    },
    [merchantColumns]
  )

  // eslint-disable-next-line no-unused-vars
  const handleChangeFilterDate = (name) => async (moment) => {
    const start = moment ? moment[0] : null
    const end = moment ? moment[1] : null
    setFilter((state) => {
      return {
        ...state,
        ticketDateStart: start,
        ticketDateEnd: end
      }
    })
    handlerFetchTableData({
      ...filter,
      ticketDateStart: start,
      ticketDateEnd: end
    })
  }

  const handleChangeType = (value) => {
    setReport(value)

    if (value === 1) {
      getTableData()
    }
    if (value === 2) {
      getProblemTableData()
    }

    // if (value === 4) {
    //   getSupportReport()
    // }
  }

  // eslint-disable-next-line no-unused-vars
  const changeCheckbox = (name) => (value) => {
    setFilter({ ...filter, ['closed']: value.target.checked })
    handlerFetchTableData()
  }

  const getTableData = useCallback(
    async (data) => {
      try {
        setLoading(true)

        const res = await axios.get(`/report/tickets`, {
          params: {
            ticketDateStart: data
              ? dateToApiDateFormat(data.ticketDateStart)
              : dateToApiDateFormat(filter.ticketDateStart),
            ticketDateEnd: data
              ? dateToApiDateFormat(data.ticketDateEnd)
              : dateToApiDateFormat(filter.ticketDateEnd),
            closed: filter.closed === true ? 'yes' : 'no'
          }
        })
        const newData = []
        for (const ticket of res.data) {
          newData.push(makeTableData(ticket))
        }

        setData(newData)
        setCurrentTableData(newData)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [filter]
  )

  const getProblemTableData = useCallback(
    async (data) => {
      try {
        setLoading(true)
        const res = await axios.get(`/report/problems`, {
          params: {
            ticketDateStart: data
              ? dateToApiDateFormat(data.ticketDateStart)
              : dateToApiDateFormat(filter.ticketDateStart),
            ticketDateEnd: data
              ? dateToApiDateFormat(data.ticketDateEnd)
              : dateToApiDateFormat(filter.ticketDateEnd),
            closed: filter.closed === true ? 'yes' : 'no'
          }
        })
        const newData = []
        for (const ticket of res.data) {
          newData.push(makeProblemTableData(ticket))
        }
        setProblemData(newData)
        setCurrentProblemTableData(newData)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [filter]
  )

  const getSupportReport = useCallback(
    async (data) => {
      try {
        setLoading(true)

        const res = await axios.get(`/report/support-report`, {
          params: {
            ticketDateStart: data
              ? dateToApiDateFormat(data.ticketDateStart)
              : dateToApiDateFormat(filter.ticketDateStart),
            ticketDateEnd: data
              ? dateToApiDateFormat(data.ticketDateEnd)
              : dateToApiDateFormat(filter.ticketDateEnd),
            closed: filter.closed === true ? 'yes' : 'no'
          }
        })
        const newData = []
        for (const ticket of res.data) {
          newData.push(makeSupportReportTableData(ticket))
        }

        setSupportReportData(newData)

        setCurrentSupportReportData(newData)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [filter]
  )

  const getSystemProblemTableData = useCallback(
    async (data) => {
      try {
        setLoading(true)
        const res = await axios.get(`/report/problems`, {
          params: {
            ticketDateStart: data
              ? dateToApiDateFormat(data.ticketDateStart)
              : dateToApiDateFormat(filter.ticketDateStart),
            ticketDateEnd: data
              ? dateToApiDateFormat(data.ticketDateEnd)
              : dateToApiDateFormat(filter.ticketDateEnd),
            closed: filter.closed === true ? 'yes' : 'no'
          }
        })
        const newData = []
        for (const ticket of res.data) {
          newData.push(makeSystemProblemTableData(ticket))
        }
        setSystemProblemData(newData)
        setCurrentSystemProblemTableData(newData)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [filter]
  )

  const handlerFetchTableData = (data) => {
    // report
    if (report === 1) {
      getTableData(data)
    }
    if (report === 2) {
      getProblemTableData(data)
    }

    if (report === 4) {
      getSupportReport(data)
    }

    // if (report === 5) {
    //   getSystemProblemTableData(data)
    // }
  }

  const downloadReportHandler = useCallback(async () => {
    try {
      if (report === 1) {
        exportToCSV(tableDataForExport(currentTableData), 'Отчет по обращениям')
      } else if (report === 2) {
        exportToCSV(
          tableDataForExport(currentProblemTableData),
          'Отчет по систменым проблемам'
        )
      } else if (report === 3) {
        const res = await axios.get('/report/merchants', {
          params: {
            ...filter
          }
        })
        exportToCSV(dataForExportMerachant(res.data), 'Отчет по мерчантам')
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter,
    currentTableData,
    currentProblemTableData,
    tableDataForExport,
    dataForExportMerachant,
    report,
    data
  ])

  const columnOptions = useMemo(() => {
    return Object.entries(COLUMNS).map(([key, value]) => ({
      id: key,
      label: value
    }))
  }, [])

  const problemColumnOptions = useMemo(() => {
    return Object.entries(PROBLEM_COLUMNS).map(([key, value]) => ({
      id: key,
      label: value
    }))
  }, [])

  const TableChangeHandler = (filter, sort, currentTable) => {
    setCurrentTableData(currentTable.currentDataSource)
  }

  const ProblemTableChangeHandler = (filter, sort, currentTable) => {
    setCurrentProblemTableData(currentTable.currentDataSource)
  }

  const optionsSaveHandler = async (data) => {
    await saveColumnOption({
      name: data.name,
      columns: JSON.stringify(data.columns)
    })
    getOptionsList()
  }

  const selectOptionHandler = (value) => {
    setSelectedColumns(JSON.parse(value.columns))
  }

  const prepareDataMailExport = (reportType) => {
    const thisColumns = () => {
      if (reportType === 1) {
        return tableColumns.filter((column) =>
          selectedColumns.includes(column.dataIndex)
        )
      }

      if (reportType === 4) {
        return supportReportTableColumns.filter((column) => column.dataIndex)
      }

      // if (reportType === 5) {
      //   return problemTableColumns.filter((column) =>
      //     selectedProblemColumns.includes(column.dataIndex)
      //   )
      // }

      return problemTableColumns.filter((column) =>
        selectedProblemColumns.includes(column.dataIndex)
      )
    }

    const headers = thisColumns().map((item) => {
      return { title: item.title, dataIndex: item.dataIndex }
    })

    return headers
  }

  return (
    <div className={styles.container}>
      <Form
        wrapperCol={{
          span: 10
        }}
        layout='vertical'
      >
        <Row>
          <Form.Item label='Дата и время обращения' className={styles.formItem}>
            <RangePicker
              value={[filter.ticketDateStart, filter.ticketDateEnd]}
              onChange={handleChangeFilterDate('ticket')}
            />
          </Form.Item>
          <Form.Item label='Тип отчета' className={styles.formItem}>
            <SingleSelect
              options={optionsTypes}
              style={{ width: '250px' }}
              value={report}
              onChange={handleChangeType}
            />
          </Form.Item>
          {(report === 1 || report === 2) && (
            <Checkbox
              className={styles.checkBox}
              checked={filter.closed}
              onChange={changeCheckbox('closed')}
            >
              Только не закрытые
            </Checkbox>
          )}
        </Row>
      </Form>
      {report === 1 && (
        <div className={styles.buttonContainer}>
          <Button
            style={{ marginBottom: '10px' }}
            loading={loading}
            htmlType='submit'
            type='primary'
            onClick={downloadReportHandler}
          >
            Выгрузить
          </Button>

          <TableSelect
            className={styles.rowSelector}
            options={columnOptions}
            onChange={setSelectedColumns}
            onSave={optionsSaveHandler}
          />

          <Button
            style={{ marginLeft: '10px' }}
            loading={loading}
            htmlType='submit'
            type='primary'
            onClick={() => setUploadReportEmailModal(true)}
          >
            Выгрузить на почту
          </Button>

          <Options
            onSelect={selectOptionHandler}
            list={optionsList}
            whenDelete={getOptionsList}
          />
        </div>
      )}

      {report === 4 && (
        <div className={styles.buttonContainer}>
          <Button
            style={{ marginBottom: '10px' }}
            loading={loading}
            htmlType='submit'
            type='primary'
            onClick={() => setUploadReportEmailModal(true)}
          >
            Выгрузить на почту
          </Button>
        </div>
      )}

      {report === 2 && (
        <div className={styles.buttonContainer}>
          <Button
            style={{ marginBottom: '10px' }}
            loading={loading}
            htmlType='submit'
            type='primary'
            onClick={downloadReportHandler}
          >
            Выгрузить
          </Button>

          <TableSelect
            className={styles.rowSelector}
            options={problemColumnOptions}
            onChange={setSelectedProblemColumns}
          />

          <Button
            style={{ marginLeft: '10px' }}
            loading={loading}
            htmlType='submit'
            type='primary'
            onClick={() => setUploadReportEmailModal(true)}
          >
            Выгрузить на почту
          </Button>
        </div>
      )}

      {report === 1 && (
        <Table
          columns={tableColumns}
          loading={loading}
          bordered
          onChange={(pagination, filter, sorter, currentTable) =>
            TableChangeHandler(filter, sorter, currentTable)
          }
          dataSource={data}
          scroll={{ x: 5200, y: 400 }}
        />
      )}
      {report === 2 && (
        <Table
          columns={problemTableColumns}
          loading={loading}
          bordered
          onChange={(pagination, filter, sorter, currentTable) =>
            ProblemTableChangeHandler(filter, sorter, currentTable)
          }
          dataSource={problemData}
          scroll={{ x: 3000, y: 400 }}
        />
      )}

      {report === 4 && (
        <Table
          columns={supportReportTableColumns}
          loading={loading}
          bordered
          onChange={(pagination, filter, sorter, currentTable) =>
            TableChangeHandler(filter, sorter, currentTable)
          }
          dataSource={supportReportData}
          scroll={{ x: 5200, y: 400 }}
        />
      )}

      {/* {report === 5 && (
        <Table
          columns={systemProblemTablecolumn}
          loading={loading}
          bordered
          onChange={(pagination, filter, sorter, currentTable) =>
            TableChangeHandler(filter, sorter, currentTable)
          }
          dataSource={systemProblemData}
          scroll={{ x: 5200, y: 400 }}
        />
      )} */}

      <UploadReportEmailModal
        filter={filter}
        data={{
          selectedColumns: prepareDataMailExport(report),
          report,
          tableColumns: () => {
            if (report === 1) {
              return tableColumns.map((item) => item.dataIndex)
            }

            if (report === 4) {
              return supportReportTableColumns.map((item) => item.dataIndex)
            }

            return problemTableColumns.map((item) => item.dataIndex)
          }
        }}
        isOpen={openUploadReportEmailModal}
        justClose={() => setUploadReportEmailModal(false)}
      />
    </div>
  )
}

export default Reports
