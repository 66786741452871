import React from 'react'
import { Input } from 'antd'

const NumericInput = (props) => {
  const { onChange, ...rest } = props

  const handleChange = (event) => {
    const { value } = event.target
    const reg = /^\d*$/
    if (
      (!isNaN(value) && !value.startsWith('0') && reg.test(value)) ||
      value === ''
    ) {
      onChange && onChange(event)
    }
  }

  return <Input onChange={handleChange} {...rest} />
}

export default NumericInput
