import { useHistory } from 'react-router'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Typography, Table, Row, Form, Input } from 'antd'
import NumericInput from 'components/NumericInput'
import useStateFromQuery from 'hooks/useStateFromQuery'

import Pagination from 'components/Pagination'
import RangePicker from 'components/RangePicker'
import MultiSelect from 'components/MultiSelect'
import StatusIndicator from 'components/StatusIndicator'

import axios from 'utils/axios'
import {
  dateToApiDateFormat,
  formatTime,
  getDateFromApiDateFormat,
  makeProblemColorIndicator
} from 'utils/helpers'

import styles from './styles.module.scss'

const { Title } = Typography

const FILTER_INITIAL = {
  search: '',
  id: '',
  dateStart: '',
  dateEnd: '',
  status: [],
  users: [],
  page: 1,
  perPage: 10
}

const Problems = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [filter, setFilter] = useStateFromQuery(FILTER_INITIAL, [
    { key: 'dateStart', cb: getDateFromApiDateFormat },
    { key: 'dateEnd', cb: getDateFromApiDateFormat },
    { key: 'users', cb: (i) => +i }
  ])
  const [pagination, setPagination] = useState({})
  const [statuses, setStatuses] = useState([])
  const [users, setUsers] = useState([])

  const getStatuses = async () => {
    const res = await axios.get('/problems/statuses')
    setStatuses(res.data)
  }

  const getUsers = useCallback(async () => {
    const res = await axios.get('/user/staff')
    res.data && setUsers(res.data.map(({ id, name }) => ({ id, label: name })))
  }, [])

  useEffect(() => {
    if (!statuses.length) {
      getStatuses()
    }

    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsStatuses = useMemo(() => {
    return statuses.map((item) => ({ id: item.id, label: item.name }))
  }, [statuses])

  const getData = useDebouncedCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/problems', {
        params: {
          ...filter,
          dateStart: dateToApiDateFormat(filter.dateStart),
          dateEnd: dateToApiDateFormat(filter.dateEnd)
        }
      })
      setData(
        res.data.map((i) => {
          let colorIndicator = makeProblemColorIndicator(i.status?.name)
          return { ...i, colorIndicator }
        })
      )
      setPagination(res.pagination)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getData()
  }, [getData, filter])

  const handleChangePagination = (value) => {
    setFilter({ ...filter, ...value })
  }

  const columns = useMemo(
    () => [
      {
        id: 1,
        title: '№',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (value, record) => (
          <StatusIndicator
            indicator={record.colorIndicator}
            content={value?.name}
          />
        )
      },
      {
        title: 'Создал',
        dataIndex: 'creator',
        key: 'creator',
        render: (value) => (value ? value?.name : '-')
      },
      {
        title: 'Время создания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value) => formatTime(value)
      },
      {
        title: 'Описание',
        dataIndex: 'description',
        key: 'description',
        render: (value) => <div className={styles.description}>{value}</div>
      },
      {
        title: 'Ответственный',
        dataIndex: 'user',
        key: 'user',
        render: (value) =>
          value ? value?.name : <div className={styles.warningText}>Нет</div>
      }
    ],
    []
  )

  const handleClickRow = (record) => {
    return {
      onClick: () => history.push(`/admin/problem/${record.id}`),
      onMouseDown: (e) => {
        if (e.button === 1) window.open(`/admin/problem/${record.id}`, '_blank')
      }
    }
  }

  const handleChangeFilter = (name) => (event) => {
    setFilter({ ...filter, [name]: event.target.value })
  }

  const handleChangeFilterSelect = (name) => (value) => {
    setFilter({ ...filter, [name]: value })
  }

  const handleChangeFilterDate = (moment, string) => {
    setFilter({ ...filter, ['dateStart']: string[0], ['dateEnd']: string[1] })
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список системных проблем
        </Title>
      </div>

      <Form
        wrapperCol={{
          span: 10
        }}
        layout='vertical'
      >
        <Row>
          <Form.Item label='Номер' className={styles.formItem}>
            <NumericInput
              value={filter.id}
              style={{ width: '100px' }}
              placeholder='№ обращения'
              onChange={handleChangeFilter('id')}
            />
          </Form.Item>
          <Form.Item label='Поиск' className={styles.formItem}>
            <Input
              value={filter.search}
              style={{ width: '250px' }}
              placeholder='По номеру проблемы и описанию'
              onChange={handleChangeFilter('search')}
            />
          </Form.Item>

          <Form.Item label='Ответственный' className={styles.formItem}>
            <MultiSelect
              mode='multiple'
              maxTagCount={1}
              maxTagTextLength={12}
              options={users}
              style={{ width: 150 }}
              value={filter.users}
              onChange={handleChangeFilterSelect('users')}
            />
          </Form.Item>

          <Form.Item label='Время создания' className={styles.formItem}>
            <RangePicker onChange={handleChangeFilterDate} />
          </Form.Item>

          <Form.Item label='Статус' className={styles.formItem}>
            <MultiSelect
              value={filter.status}
              mode='multiple'
              style={{ width: '250px' }}
              options={optionsStatuses}
              maxTagCount={1}
              maxTagTextLength={16}
              onChange={handleChangeFilterSelect('status')}
            />
          </Form.Item>
        </Row>
      </Form>

      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        onRow={handleClickRow}
        pagination={false}
        scroll={{ x: 1200 }}
        rowClassName={styles.row}
      />
      <Pagination onChange={handleChangePagination} pagination={pagination} />
    </div>
  )
}

export default Problems
