import React from 'react'
import Layout from './components/Layout'
import ReactNotification from 'react-notifications-component'
import { BrowserRouter } from 'react-router-dom'
import { ProvideAppContext } from 'hooks/useContext'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import styles from './app.module.scss'
import './style.scss'

function App() {
  return (
    <ProvideAppContext>
      <BrowserRouter>
        <ConfigProvider locale={ruRU}>
          <Layout />
        </ConfigProvider>
      </BrowserRouter>

      <ReactNotification className={styles.notification} />
    </ProvideAppContext>
  )
}

export default App
