import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input } from 'antd'

import Error from 'components/Error'
import ConditionalRender from 'components/ConditionalRender'
import { PhotoModal } from './modals/PhotoModal'

import io from 'socket.io-client'

import { formatTime, getFilesByType } from 'utils/helpers'

import { useProfile } from 'hooks/useContext'
import { useNotification } from 'hooks/useNotification'
import { useMessage } from 'api/Messages'

import styles from './styles.module.scss'
import { UserOutlined, FolderOutlined } from '@ant-design/icons'

const { Meta } = Card

export const Chat = ({ data }) => {
  const { getTicketMessages, sendMessage, messageRead, loading } = useMessage()
  const { createNotification } = useNotification()
  const { user } = useProfile()

  const [messages, setMessages] = useState([])
  const [fileModal, setFileModal] = useState({
    open: false,
    files: [],
    images: []
  })

  const getMessage = useCallback(async () => {
    const res = await getTicketMessages(data.id)
    setMessages(res)
  }, [getTicketMessages, data])

  useEffect(() => {
    getMessage()
  }, [getMessage])

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_SOCKET)
    socket.on('message', () => getMessage())

    return () => socket.disconnect()
  }, [getMessage])

  useEffect(() => {
    // здесь будем обновлять статуст у ticket newMessage
    const update = setTimeout(async () => {
      await messageRead(data.id)
    }, 1000)

    return () => {
      clearTimeout(update)
    }
  })

  const handleSave = async (value) => {
    const res = await sendMessage(value)
    createNotification(res, 'success')
    resetForm()
    getMessage()
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        message: '',
        ticketId: data.id,
        chatId: data.chatId,
        author: user?.name
      },
      validationSchema: yup.object().shape({
        message: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (ev) => {
    setFieldValue(name, ev.target.value)
  }

  const openFileModal = (files) => () => {
    const tempFiles = getFilesByType(files, false)
    const tempImages = getFilesByType(files, true)
    setFileModal({ open: true, files: tempFiles, images: tempImages })
  }

  const closeFileModal = () => {
    setFileModal({ open: false, files: [], images: [] })
  }

  if (data.chatId === null) {
    return <div>Кажется пользователь оставил обращение не в Telegram</div>
  }

  return (
    <div className={styles.commentTabContainer}>
      <div className={styles.commentsContainer}>
        {messages?.length ? (
          messages.map((message) => (
            <Card className={styles.commentCard} key={message.id}>
              {message?.files.length > 0 && (
                <FolderOutlined
                  className={styles.chatFolderIcon}
                  onClick={openFileModal(message.files)}
                />
              )}
              <Meta
                avatar={<UserOutlined className={styles.userAvatar} />}
                title={
                  <div className={styles.commentTitle}>
                    <p className={styles.commentName}>{message.author}</p>
                    <p className={styles.commentTime}>
                      {formatTime(message.createdAt)}
                    </p>
                  </div>
                }
                description={
                  <div className={styles.comment}>{message.message}</div>
                }
              />
            </Card>
          ))
        ) : (
          <div>Нет сообщений</div>
        )}
      </div>

      <ConditionalRender roles={[1, 2]}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item
            required
            extra={touched.message && <Error message={errors.message} />}
          >
            <Input.TextArea
              value={values.message}
              placeholder='Комментарий'
              onChange={handleChange('message')}
              style={{ width: 500, marginTop: 25 }}
              className={styles.answerTextarea}
              rows='6'
            />
          </Form.Item>
          <Button type='primary' loading={loading} htmlType='submit'>
            Отправить
          </Button>
        </Form>
      </ConditionalRender>

      <PhotoModal
        isOpen={fileModal.open}
        justClose={closeFileModal}
        // files={fileModal.files}
        photoFiles={fileModal.images}
        otherFiles={fileModal.files}
      />
    </div>
  )
}
