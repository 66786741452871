import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import clsx from 'clsx'
import { CLEAR_EDITOR_COMMAND } from 'lexical'
import ExampleTheme from './themes/ExampleTheme'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { TRANSFORMERS } from '@lexical/markdown'
import ImagesPlugin from './plugins/ImagesPlugin'
import { ImageNode } from './nodes/ImageNode'
import { useSharedHistoryContext } from './context/SharedHistoryContext'

import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import './styles.scss'

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode
  ]
}

const EditorWrapper = forwardRef((props, ref) => {
  return (
    <LexicalComposer
      initialConfig={{ ...editorConfig, readOnly: props.readOnly }}
    >
      <Editor {...props} ref={ref} />
    </LexicalComposer>
  )
})

const Editor = forwardRef((props, ref) => {
  const { readOnly, initialState } = props

  const [editor] = useLexicalComposerContext()
  const {historyState} = useSharedHistoryContext()

  useImperativeHandle(ref, () => ({
    getState() {
      return editor.getEditorState().toJSON()
    },
    clear() {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND)
    }
  }))

  useEffect(() => {
    if (readOnly !== editor.isReadOnly) {
      editor.setReadOnly(readOnly)
    }
  }, [editor, readOnly])

  useEffect(() => {
    if (initialState) {
      const state = editor.parseEditorState(initialState)
      editor.setEditorState(state)
    }
  }, [editor, initialState])

  return (
    <div className='editor-container'>
      {!readOnly && <ToolbarPlugin />}
      <div
        className={clsx('editor-inner', !readOnly && 'editor-inner-editable')}
      >
        <RichTextPlugin
          contentEditable={<ContentEditable className='editor-input' />}
          placeholder={
            <div className='editor-placeholder'>
              {readOnly ? 'Тут ничего нет ¯\\_(ツ)_/¯' : 'Введите текст...'}
            </div>
          }
        />
        <HistoryPlugin externalHistoryState={historyState} />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <ImagesPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      </div>
    </div>
  )
})

export default EditorWrapper
