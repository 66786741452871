import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Table, Typography, Button, Modal, Form, Input } from 'antd'

import Error from 'components/Error'
import MultiSelect from 'components/MultiSelect'

import axios from 'utils/axios'

import styles from './styles.module.scss'
import { EditOutlined } from '@ant-design/icons'

const { Title } = Typography

const Users = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState([])

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/user')
      setData(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        id: 2,
        title: '',
        key: 'id',
        dataIndex: 'id',
        render: (text, record) => (
          <div className={styles.tableButtons}>
            <EditOutlined
              className={styles.icons}
              onClick={handleEditUser(record)}
            />
          </div>
        )
      }
    ],
    []
  )

  const handleEditUser = () => () => {
    alert('Пока не работает')
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleJustCloseModal = () => {
    setModalOpen(false)
  }

  const handleOnCloseModal = () => {
    setUser([])
    handleJustCloseModal()
    getData()
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список учётных записей
        </Title>
        <Button type='primary' onClick={handleOpenModal}>
          Добавить пользователя
        </Button>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        rowClassName={styles.row}
      />
      <UserModal
        user={user}
        justClose={handleJustCloseModal}
        onClose={handleOnCloseModal}
        isOpen={modalOpen}
      />
    </div>
  )
}

export default Users

const UserModal = (props) => {
  const { user, isOpen, onClose, justClose } = props

  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])

  const getRoles = useCallback(async () => {
    const res = await axios.get('/role')
    setRoles(res.data)
  }, [])

  useEffect(() => {
    if (!roles.length) {
      getRoles()
    }
  }, [getRoles, roles.length])

  const optionsRoles = useMemo(() => {
    return roles.map((item) => ({ id: item.id, label: item.name }))
  }, [roles])

  const handleSave = async (value) => {
    try {
      setLoading(true)
      user?.id
        ? await axios.put(`/user/${user.id}`, value)
        : await axios.post('/user/create', value)
      resetForm()
      setTimeout(() => onClose(), 100)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        name: user ? user.name : '',
        email: user ? user.email : '',
        password: '',
        repPass: '',
        roles: []
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Это поле обязательно'),
        email: yup
          .string()
          .required('Это поле обязательно')
          .email('Не верный формат почты'),
        password: yup.string().required('Это поле обязательно'),
        repPass: yup
          .string()
          .required('Это поле обязательно')
          .test('match', 'Пароли не совпадают', function (repPass) {
            if (repPass && this.parent.password !== '') {
              if (repPass === this.parent.password) {
                return true
              } else {
                return false
              }
            }
          }),
        roles: yup
          .array()
          .min(1, 'Хотя бы одна роль')
          .required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleChangeSelect = (name) => (value) => {
    setFieldValue(name, value)
  }

  return (
    <Modal visible={isOpen} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        <Form style={{ width: '100%' }} layout='vertical'>
          <Form.Item
            label='Имя'
            extra={touched.name && <Error message={errors.name} />}
          >
            <Input
              placeholder='Иванов Иван'
              value={values.name}
              onChange={handleChange('name')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item
            label='Email'
            extra={touched.email && <Error message={errors.email} />}
          >
            <Input
              placeholder='ivanov.i@gmail.com'
              value={values.email}
              onChange={handleChange('email')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item
            label='Пароль'
            extra={touched.password && <Error message={errors.password} />}
          >
            <Input.Password
              value={values.password}
              onChange={handleChange('password')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item
            label='Повторите пароль'
            extra={touched.repPass && <Error message={errors.repPass} />}
          >
            <Input.Password
              value={values.repPass}
              onChange={handleChange('repPass')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item
            label='Роль'
            extra={touched.roles && <Error message={errors.roles} />}
          >
            <MultiSelect
              value={values.roles}
              placeholder='Администратор'
              options={optionsRoles}
              mode='multiple'
              onChange={handleChangeSelect('roles')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 15 }}>
            <Button loading={loading} type='primary' onClick={handleSubmit}>
              {user?.name ? 'Изменить' : 'Создать'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
