import { useParams } from 'react-router-dom'
import React, { Fragment, useEffect, useState } from 'react'
import { Result, Spin } from 'antd'

import { useTicketInfo } from 'api/Client/ticketInfo'

import { DoubleRow } from 'pages/Form'

import styles from './styles.module.scss'

export const TicketInfo = () => {
  const params = useParams()
  const { getTicket, loading } = useTicketInfo()

  const [ticket, setTicket] = useState([])

  const loadTicket = async () => {
    const res = await getTicket(params.id)
    setTicket(res)
  }

  useEffect(() => {
    loadTicket()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // if (window.parent != window.top) {
    window.parent.postMessage({ event: 'ticket-id', value: params.id }, '*')
    // }
  }, [params.id])

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spin className={styles.spinner} />
      </div>
    )
  }

  if (!ticket?.id) {
    return (
      <Result
        status='warning'
        className={styles.resultInfo}
        title='Обращение не найдено'
      />
    )
  }

  if (ticket.currentStatusId >= 1 && ticket.currentStatusId < 4) {
    return (
      <Result
        status='info'
        className={styles.resultInfo}
        title={
          <DoubleRow
            firstPart={
              <Fragment>
                Ваше обращение <strong>№{params.id}</strong> находится в работе.
              </Fragment>
            }
            secondPart={
              ticket.answer && (
                <div>
                  <div className={styles.supportAnswer}>
                    Ответ технической поддержки:
                  </div>
                  <div className={styles.selfAnswer}>{ticket.answer}</div>
                </div>
              )
            }
          />
        }
      />
    )
  }

  if (ticket.currentStatusId === 4) {
    return (
      <Result
        status='success'
        className={styles.resultInfo}
        title={
          <DoubleRow
            firstPart={
              <Fragment>
                Ваша заявка <strong>№{params.id}</strong> обработана.
              </Fragment>
            }
            secondPart={
              ticket.answer && (
                <div>
                  <div className={styles.supportAnswer}>
                    Ответ технической поддержки:
                  </div>
                  <div className={styles.selfAnswer}>{ticket.answer}</div>
                </div>
              )
            }
          />
        }
      />
    )
  }
}
