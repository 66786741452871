import * as yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { Button, Form, Input } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import Error from 'components/Error'
import SingleSelect from 'components/SingleSelect'

import DatePickerField from 'components/DatePicker'
import ConditionalRender from 'components/ConditionalRender'
import { useHistory as useEventHistory, EVENTS } from 'api/History'
import axios from 'utils/axios'

import styles from './styles.module.scss'

const DecisionTab = ({ data, update }) => {
  const [statuses, setStatuses] = useState([])
  const [loading, setLoading] = useState(false)
  const [isChanging, setIsChanging] = useState(false)
  const { createHistory } = useEventHistory()
  const getStatuses = async () => {
    const res = await axios.get('/problems/statuses')
    setStatuses(res.data)
  }

  useEffect(() => {
    if (!statuses.length) {
      getStatuses()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsStatuses = useMemo(() => {
    return statuses.map((item) => ({ id: item.id, label: item.name }))
  }, [statuses])

  const handleSave = async (value) => {
    try {
      setLoading(true)
      value.productionTime =
        value.productionTime !== null
          ? moment(value.productionTime).format('YYYY-MM-DD HH:mm:ss.SSSZZ')
          : null
      await axios.put(`/problems/${data.id}/decision`, value)

      setIsChanging(false)
      resetForm()
      update()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        statusId: data.status?.id || '',
        productionTime: data.productionTime
          ? moment(data.productionTime)
          : null,
        decision: data.decision || '',
        link: data.link || null
      },
      validationSchema: yup.object().shape({
        statusId: yup.string().required('Это поле обязательно'),
        productionTime: yup
          .mixed()
          .when('statusId', {
            is: '4',
            then: yup.mixed().required('Это поле обязательно')
          })
          .when('statusId', {
            is: '5',
            then: yup.mixed().required('Это поле обязательно')
          }),
        decision: yup
          .string()
          .when('statusId', {
            is: '4',
            then: yup.string().required('Это поле обязательно')
          })
          .when('statusId', {
            is: '5',
            then: yup.string().required('Это поле обязательно')
          }),
        link: yup.mixed().notRequired()
      }),
      enableReinitialize: true
    })

  const handleEditForm = async () => {
    setIsChanging(true)
  }

  const handleCancelEdit = () => {
    setIsChanging(false)
  }

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleChangeSelect = (name) => (value) => {
    setFieldValue(name, value)
  }

  const handleChangeDate = (name) => (value) => {
    setFieldValue(name, moment(value))
  }

  return (
    <Form
      labelCol={{
        span: 3
      }}
      wrapperCol={{
        span: 10
      }}
      layout='vertical'
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Статус системной проблемы'
        extra={touched.statusId && <Error message={errors.statusId} />}
      >
        <SingleSelect
          placeholder='Статус'
          options={optionsStatuses}
          disabled={!isChanging && data.status?.id > 1}
          onChange={handleChangeSelect('statusId')}
          value={values.statusId}
        />
      </Form.Item>

      <Form.Item
        label='Время запуска решения в продакшен'
        extra={
          touched.productionTime && <Error message={errors.productionTime} />
        }
      >
        <DatePickerField
          selected={values.productionTime?._d}
          className={styles.datePick}
          timeIntervals={30}
          disabled={!isChanging && data.decision !== null}
          onChange={handleChangeDate('productionTime')}
        />
      </Form.Item>

      <Form.Item
        label='Ссылка на AzurDevOps'
        extra={touched.link && <Error message={errors.link} />}
      >
        {!isChanging && data.link !== null && (
          <a className={styles.linkAzur} href={data.link} target='blank'>
            AzurDevOps
          </a>
        )}
        {(data.link === null || (isChanging && data.link !== null)) && (
          <Input
            value={values.link}
            onChange={handleChange('link')}
            disabled={!isChanging && data.status.id > 1}
          />
        )}
      </Form.Item>

      <Form.Item
        label='Описание решения'
        extra={touched.decision && <Error message={errors.decision} />}
      >
        <Input.TextArea
          value={values.decision}
          disabled={!isChanging && data.decision !== null}
          onChange={handleChange('decision')}
          autoSize={{ minRows: 5 }}
        />
      </Form.Item>

      <ConditionalRender roles={[1, 2]}>
        {data.decision === null &&
        data.status?.id === 1 &&
        data.productionTime === null ? (
          <Button htmlType='submit' loading={loading} type='primary'>
            Сохранить
          </Button>
        ) : (
          <>
            <Button
              onClick={isChanging ? handleSubmit : handleEditForm}
              loading={loading}
              type='primary'
            >
              {isChanging ? 'Сохранить' : 'Изменить'}
            </Button>
            {isChanging && (
              <Button onClick={handleCancelEdit} type='default'>
                Отменить
              </Button>
            )}
          </>
        )}
      </ConditionalRender>
    </Form>
  )
}

export default DecisionTab
