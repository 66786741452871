import * as yup from 'yup'
import { useFormik } from 'formik'
import { Modal, Button, Input, Form } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'

import Error from 'components/Error'
import SingleSelect from 'components/SingleSelect'

import { useChangeProblem } from 'api/Problems'
import { useStorage } from 'hooks/useContext'

import styles from './styles.module.scss'

export const EditModal = ({ isOpen, justClose, data, onClose }) => {
  const { problemUsers, loadProblemUsers } = useStorage()

  const [loading, setLoading] = useState(false)
  const { editInfo } = useChangeProblem()

  useEffect(() => {
    loadProblemUsers()
  }, [loadProblemUsers])

  const usersOption = useMemo(() => {
    return problemUsers.map((user) => ({ id: user.id, label: user.name }))
  }, [problemUsers])

  const handleSave = async (value) => {
    try {
      setLoading(true)
      await editInfo(data.id, value)
      resetForm()
      onClose()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    justClose()
    resetForm()
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        name: data.name ? data.name : '',
        description: data.description ? data.description : '',
        responsibleUserId: data.responsible?.id || null
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Это поле обязательно'),
        description: yup.string().required('Это поле обязательно'),
        responsibleUserId: yup.string().nullable(true)
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const changeSelectHandler = (value) => {
    setFieldValue('responsibleUserId', value)
  }

  return (
    <Modal visible={isOpen} footer={null} onCancel={handleCloseModal}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item
            required
            label='Название'
            extra={touched.name && <Error message={errors.name} />}
          >
            <Input value={values.name} onChange={handleChange('name')} />
          </Form.Item>
          <Form.Item
            label='Описание'
            extra={
              touched.description && <Error message={errors.description} />
            }
          >
            <Input
              value={values.description}
              onChange={handleChange('description')}
              onPressEnter={handleSubmit}
            />
          </Form.Item>
          <Form.Item
            label='Ответственный за реализацию'
            extra={
              touched.responsibleUserId && (
                <Error message={errors.responsibleUserId} />
              )
            }
          >
            <SingleSelect
              value={values.responsibleUserId}
              options={usersOption}
              onChange={changeSelectHandler}
            />
          </Form.Item>

          <Button loading={loading} type='primary' htmlType='submit'>
            Сохранить
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
