import { useProfile } from 'hooks/useContext'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export default ({ path, component, allow = false }) => {
  const { user } = useProfile()

  const isAdmin = allow
    ? user?.roles?.find((item) => item.id === 1 || item.id === 2)
    : user?.roles?.find((item) => item.id === 1)

  return isAdmin ? (
    <Route exact path={path} component={component} />
  ) : (
    <Redirect to='/admin/tickets' />
  )
}
