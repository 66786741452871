import React from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import InputMask from 'react-input-mask'
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Upload,
  message,
  Tag
} from 'antd'

import { useEffect, useMemo, useState } from 'react'

import Error from 'components/Error'
import DatePicker from 'components/DatePicker'
import SingleSelect from 'components/SingleSelect'

import { useHistory } from 'react-router-dom'

import axios from 'utils/axios'
import { normalizeKiosk } from 'utils/helpers'
import { useRoby } from 'hooks/useContext'
import { UploadOutlined } from '@ant-design/icons'

import './styles.scss'
import 'antd/dist/antd.css'
import styles from './styles.module.scss'

export const NewTicketFormModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [problemPlace, setProblemPlace] = useState([])
  const [startDate] = useState(moment().subtract(5, 'minutes'))
  const [kiosks, setKiosks] = useState([])
  const [messageApi, contextHolder] = message.useMessage()

  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: ''
  })

  const handleCancel = () => setPreview({ ...preview, visible: false })

  const { fetchJson } = useRoby()
  const history = useHistory()

  const getKiosk = React.useCallback(async () => {
    const tempKiosks = []
    let res
    try {
      res = await fetchJson(7)
    } catch {
      return
    }
    res.data.forEach((el) => {
      if (!el.testMode) {
        tempKiosks.push(el)
      }
    })
    setKiosks(tempKiosks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseModal = () => {
    resetForm()
    onClose()
  }

  const optionsKiosks = useMemo(() => {
    return kiosks.map((item) => ({
      id: normalizeKiosk(item),
      label: normalizeKiosk(item)
    }))
  }, [kiosks])

  const getProblemPlace = async () => {
    const res = await axios.get('/client/ticket/problemplace')
    setProblemPlace(res.data)
  }

  useEffect(() => {
    getProblemPlace()
    // getKiosk()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsPlaces = React.useMemo(() => {
    return problemPlace.map((item) => ({ id: item.id, label: item.name }))
  }, [problemPlace])

  const handleSave = async (value) => {
    try {
      setLoading(true)
      const data = value
      const now = moment().format('YYYY-MM-DD HH:mm:ss.SSSZZ')
      data.formFilledDate = now
      data.detectedAt = moment(value.detectedAt).format(
        'YYYY-MM-DD HH:mm:ss.SSSZZ'
      )
      const formData = new FormData()

      for (let key in value) {
        if (key === 'photo') {
          if (value[key]) {
            for (let i = 0; i < value[key].length; i++) {
              formData.append(key, value[key][i].originFileObj)
            }
          } else {
            formData.append(key, value[key])
          }
        } else if (key === 'detectedAt') {
          formData.append(
            key,
            moment(value[key]).format('YYYY-MM-DD HH:mm:ss.SSSZZ')
          )
        } else {
          formData.append(key, value[key])
        }
      }

      const res = await axios.post('/ticket/create-ticket', formData)
      console.log(res.data.id)
      handleCloseModal()
      history.push(`/admin/ticket/${res.data.id}`)
      console.log(res.data)
      //
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (name) => (event) =>
    setFieldValue(name, event.target.value)

  const handleChangeDate = (name) => (value) =>
    setFieldValue(name, moment(value))

  const handleChangeSelect = (name) => (value) => setFieldValue(name, value)

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        clientName: '',
        clientPhone: '',
        truthKiosk: '',
        problemPlaceId: '',
        photo: undefined,
        description: '',
        detectedAt: startDate
      },
      validationSchema: yup.object().shape({
        clientName: yup.string().required('Это поле обязательно'),
        clientPhone: yup
          .string()
          .required('Это поле обязательно')
          .matches(
            /^(\+7|7|8)?[\s/-]?\(?[4893][0-9]{2}\)?[\s/-]?[0-9]{3}[\s/-]?[0-9]{2}[\s/-]?[0-9]{2}$/,
            'Неверный формат'
          ),
        problemPlaceId: yup.string().required('Это поле обязательно'),
        truthKiosk: yup.string().when('problemPlaceId', {
          is: '1',
          then: yup.string().required('Это поле обязательно')
        }),
        photo: yup.mixed().notRequired().yup,
        description: yup.string().required('Это поле обязательно'),
        detectedAt: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChangePhoto = (name) => (value) => {
    setFieldValue(name, value.fileList)
  }

  const handlePreview = (file) => {
    if (file.size > 600000) {
      return false
    }
    setPreview({
      visible: true,
      image: file.thumbUrl,
      title: file.name
    })
  }

  const handlerErrorMessage = () => {
    messageApi.open({
      type: 'error',
      content: 'Загружаемый файл больше допустимого размера 5 мегабайт',
      duration: 8
    })
  }

  return (
    <Modal footer={false} visible={open} onCancel={handleCloseModal}>
      <div className={styles.form_container}>
        <Form onFinish={handleSubmit} layout='vertical'>
          <Row>
            <Col span={11}>
              <Form.Item
                required
                label='Имя'
                extra={
                  touched.clientName && <Error message={errors.clientName} />
                }
              >
                <Input
                  value={values.clientName}
                  onChange={handleChange('clientName')}
                />
              </Form.Item>
            </Col>
            <Col offset={1} span={12}>
              <Form.Item
                required
                label='Контактный номер телефона'
                extra={
                  touched.clientPhone && <Error message={errors.clientPhone} />
                }
              >
                <InputMask
                  value={values.clientPhone}
                  className={styles.inputMask}
                  mask={'+7-(999)-999-99-99'}
                  onChange={handleChange('clientPhone')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            required
            label='Место возникновения проблемы'
            extra={
              touched.problemPlaceId && (
                <Error message={errors.problemPlaceId} />
              )
            }
          >
            <SingleSelect
              id='createTicketFormProblemPlace'
              options={optionsPlaces}
              value={values.problemPlaceId}
              onChange={handleChangeSelect('problemPlaceId')}
            />
          </Form.Item>
          {values.problemPlaceId === 1 && (
            // <Form.Item
            //   required
            //   label='Киоск'
            //   extra={touched.kiosk && <Error message={errors.kiosk} />}
            // >
            //   <Input
            //     value={values.kiosk}
            //     onChange={handleChange('kiosk')}
            //     placeholder='Адрес включая город/№ киоска'
            //   />
            // </Form.Item>

            <Form.Item
              label='Адрес киоска'
              required
              extra={
                touched.truthKiosk && <Error message={errors.truthKiosk} />
              }
            >
              <SingleSelect
                showSearch={true}
                value={values.truthKiosk}
                options={optionsKiosks}
                onChange={handleChangeSelect('truthKiosk')}
              />
            </Form.Item>
          )}
          <Form.Item
            required
            label={`Дата и время возникновения проблемы`}
            extra={touched.detectedAt && <Error message={errors.detectedAt} />}
          >
            <DatePicker
              selected={values.detectedAt._d}
              timeIntervals={30}
              onChange={handleChangeDate('detectedAt')}
            />
          </Form.Item>

          <Form.Item
            required
            label='Проблема'
            extra={
              touched.description && <Error message={errors.description} />
            }
          >
            <Input.TextArea
              value={values.description}
              onChange={handleChange('description')}
              autoSize={{ minRows: 5 }}
            />
          </Form.Item>

          <Form.Item
            label='Файлы'
            extra={touched.photo && <Error message={errors.photo} />}
          >
            <Upload
              listType='picture'
              defaultFileList={values.photo}
              onChange={handleChangePhoto('photo')}
              onPreview={handlePreview}
              supportServerRender={null}
              multiple
              maxCount={5}
              beforeUpload={(file) => {
                if (file.size > 600000) {
                  handlerErrorMessage('error')
                  return true
                }
                return false
              }}
            >
              <Button icon={<UploadOutlined />}>Загрузить файлы</Button>
            </Upload>

            <div className={styles.notif}>
              <Tag>Максимальное количество файлов 5</Tag>
              <Tag>Максимальный размер файла 5МБ</Tag>
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 9 }}>
            <Button loading={loading} htmlType='submit' type='primary'>
              Отправить
            </Button>
          </Form.Item>
        </Form>
        <Modal
          visible={preview.visible}
          title={preview.title}
          onCancel={handleCancel}
          footer={null}
        >
          <img
            alt={preview.title}
            style={{ width: '100%' }}
            src={preview.image}
          />
        </Modal>
        {contextHolder}
      </div>
    </Modal>
  )
}

export const DoubleRow = (props) => {
  const { firstPart, secondPart, secondSmall = false } = props
  return (
    <div>
      <p className={clsx(styles.rowP, styles.firstRow)}>{firstPart}</p>
      <p className={clsx(styles.rowP, secondSmall && styles.smallP)}>
        {secondPart}
      </p>
    </div>
  )
}
