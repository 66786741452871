import { useCallback, useState } from "react"
import axios from 'utils/axios'


export const useTicketInfo = () => {

  const [loading, setLoading] = useState(false)

  const getTicket = useCallback(async (id) => {
    try {
      setLoading(true)
      const res = await axios.get(`/client/ticket/${id}`)
      return res.data
    }
    catch (e) {
      console.log(e)
    }
    finally { setLoading(false) }
  }, [])


  return { getTicket, loading }
}