import React from 'react'
import { Modal, Result, Button } from 'antd'


export const CautionModal = ({
  title,
  onClose,
  onFinish,
  isOpen
}) => {

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose} >Отменить</Button>
          <Button onClick={onFinish} type='primary'>Удалить</Button>
        </>
      }
    >
      <Result
        status='warning'
        title={title}
      />
    </Modal>
  )
}