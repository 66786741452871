import React, { useCallback, useEffect, useState } from 'react'
import { Timeline, Tag } from 'antd'
import styles from './styles.module.scss'
import { EVENTS } from 'api/History'
import { formatTime } from 'utils/helpers'

import axios from 'utils/axios'
export const History = ({ data }) => {
  const [history, setHisotry] = useState([])
  const [loading, setLoading] = useState(false)

  const getHistory = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/history/${data.id}`)
      setHisotry(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getHistory()
  }, [getHistory])

  return (
    <div className={styles.timelineContainer}>
      {history.length > 0 ? (
        <Timeline mode='right'>
          {history.map((item) => {
            if (
              item.event.id === EVENTS.TRASFER_ANOTHER_EMPLOYEE &&
              item.meta !== null
            ) {
              return (
                <Timeline.Item label={formatTime(item.createdAt)} key={item.id}>
                  {`Пользователь`}{' '}
                  <span
                    className={styles.userLabel}
                  >{`${item.user.name}`}</span>{' '}
                  {`делегировал обращение пользователю`}
                  <span className={styles.userLabel}>{`    ${item.meta}`}</span>
                </Timeline.Item>
              )
            }
            return (
              <Timeline.Item label={formatTime(item.createdAt)} key={item.id}>
                {`${item.event.name} | ${item.user.name}`}
              </Timeline.Item>
            )
          })}
        </Timeline>
      ) : (
        <div>История отсутствует</div>
      )}
    </div>
  )
}
