/* eslint-disable no-unused-vars */
import DatePicker, { registerLocale } from 'react-datepicker'
import ruLang from 'date-fns/locale/ru'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './styles.module.scss'
import clsx from 'clsx'

registerLocale('ru', ruLang)

const DatePickerField = ({
  showTimeSelect = true,
  className,
  defaultValue,
  timeIntervals,
  selected,
  dateFormat = 'dd.MM.yyyy HH:mm',
  onChange,
  ...rest
}) => {
  return (
    <DatePicker
      className={clsx(styles.datePicker, className)}
      locale='ru'
      showTimeSelect
      selected={selected}
      timeCaption='Время'
      timeIntervals={timeIntervals}
      dateFormat={dateFormat}
      onChange={onChange}
      {...rest}
    />
  )
}

export default DatePickerField
