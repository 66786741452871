/* eslint-disable no-unused-vars */
import moment from 'moment'
import { searchFilter } from './SearchFilter'
import { parseTimeToMins } from 'utils/helpers'

export const tColumns = ({
  channels,
  problemPlaces,
  merchants,
  staff,
  softwares,
  problemUsers
}) => {
  return [
    {
      title: '№ Обращения',
      dataIndex: 'id',
      key: 'id',
      width: 130,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Текущий статус',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      width: 120,
      ...searchFilter('currentStatus', {
        type: 'checkbox',
        options: [
          {
            text: 'Анализ обращения',
            value: 'Анализ обращения'
          },
          {
            text: 'Закрыто',
            value: 'Закрыто'
          },
          {
            text: 'Временное решение',
            value: 'Временное решение'
          },
          {
            text: 'Новое обращение',
            value: 'Новое обращение'
          }
        ]
      })
    },
    {
      title: 'Канал обращения',
      dataIndex: 'channel',
      key: 'channel',
      width: 120,
      className: 'table_acception_color',
      ...searchFilter('channel', {
        type: 'checkbox',
        options: channels.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Источник',
      dataIndex: 'clientName',
      key: 'clientName',
      width: 120,
      className: 'table_acception_color',
      ...searchFilter('clientName', {
        type: 'text'
      })
    },
    {
      title: 'Телефон источника',
      dataIndex: 'clientPhone',
      key: 'clientPhone',
      width: 160,
      className: 'table_acception_color',
      ...searchFilter('clientPhone', {
        type: 'text'
      })
    },
    {
      title: 'Email источника',
      dataIndex: 'clientEmail',
      key: 'clientEmail',
      width: 110,
      className: 'table_acception_color',
      ...searchFilter('clientEmail', {
        type: 'text'
      })
    },
    {
      title: 'Место проблемы(от пользователя)',
      dataIndex: 'problemPlace',
      key: 'problemPlace',
      className: 'table_acception_color',
      width: 190,
      ...searchFilter('problemPlace', {
        type: 'checkbox',
        options: problemPlaces.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Адрес места проблемы (если есть)',
      dataIndex: 'truthKiosk',
      key: 'truthKiosk',
      width: 250,
      className: 'table_acception_color',
      ...searchFilter('truthKiosk', {
        type: 'text'
      })
    },
    {
      title: 'Мерчант',
      dataIndex: 'merchant',
      key: 'merchant',
      width: 120,
      className: 'table_acception_color',
      ...searchFilter('merchant', {
        type: 'checkbox',
        options: merchants.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Дата и время обращения',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      className: 'table_acception_color',
      sorter: (a, b) =>
        moment(a.createdAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.createdAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Дата и время проявления проблемы',
      dataIndex: 'detectedAt',
      key: 'detectedAt',
      width: 120,
      className: 'table_acception_color',
      sorter: (a, b) =>
        moment(a.detectedAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.detectedAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Описание проблемы на языке пользователя',
      dataIndex: 'description',
      key: 'description',
      width: 350,
      className: 'table_acception_color',
      ...searchFilter('description', {
        type: 'text'
      })
    },
    {
      title: 'Сотрудник, взявший в работу',
      dataIndex: 'analysUser',
      key: 'analysUser',
      width: 123,
      className: 'table_acception_color',
      ...searchFilter('analysUser', {
        type: 'checkbox',
        options: staff.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Время реакции на обращение',
      dataIndex: 'startWorkDelta',
      key: 'startWorkDelta',
      width: 125,
      className: 'table_acception_color',
      sorter: (a, b) =>
        parseTimeToMins(a.startWorkDelta) - parseTimeToMins(b.startWorkDelta)
    },
    {
      title: 'Время взятия в работу',
      dataIndex: 'analysStartedAt',
      key: 'analysStartedAt',
      width: 120,
      className: 'table_analys_color'
      // sorter: (a, b) => moment(a.analysStartedAt, 'DD.MM.YYYY HH:mm').unix() - moment(b.analysStartedAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Тип обращения',
      dataIndex: 'type',
      key: 'type',
      width: 125,
      className: 'table_analys_color',
      ...searchFilter('type', {
        type: 'checkbox',
        options: [
          {
            text: 'Проблема',
            value: 'Проблема'
          },
          {
            text: 'Консультация',
            value: 'Консультация'
          },
          {
            text: 'Запрос на улучшение',
            value: 'Запрос на улучшение'
          }
        ]
      })
    },
    {
      title: 'Локализация проблемы (если есть)',
      dataIndex: 'software',
      key: 'software',
      className: 'table_analys_color',
      width: 250,
      ...searchFilter('software', {
        type: 'checkbox',
        options: softwares.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Результат анализа (описание на тех языке)',
      dataIndex: 'analys',
      key: 'analys',
      className: 'table_analys_color',
      width: 350,
      ...searchFilter('analys', {
        type: 'text'
      })
    },
    {
      title: 'Время затраченное на анализ обращения',
      dataIndex: 'analysDelta',
      key: 'analysDelta',
      width: 120,
      className: 'table_analys_color',
      sorter: (a, b) =>
        parseTimeToMins(a.analysDelta) - parseTimeToMins(b.analysDelta)
    },
    {
      title: 'Сотрудник, выполнивший временное решение',
      dataIndex: 'decisionUser',
      key: 'decisionUser',
      className: 'table_decision_color',
      width: 170,
      filters: staff.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.decisionUser === value
    },
    {
      title: 'Описание временного решения',
      dataIndex: 'tempDecision',
      key: 'tempDecision',
      className: 'table_decision_color',
      width: 250,
      ...searchFilter('tempDecision', {
        type: 'text'
      })
    },
    {
      title: 'Время применения временного решения',
      dataIndex: 'decisionStartedAt',
      key: 'decisionStartedAt',
      width: 123,
      className: 'table_decision_color'
      // sorter: (a, b) => moment(a.decisionStartedAt, 'DD.MM.YYYY HH:mm').unix() - moment(b.decisionStartedAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Время затраченное на решение по обращению',
      dataIndex: 'decisionDelta',
      key: 'decisionDelta',
      width: 123,
      className: 'table_decision_color',
      sorter: (a, b) =>
        parseTimeToMins(a.decisionDelta) - parseTimeToMins(b.decisionDelta)
    },
    {
      title: 'Время регистрации системной проблемы',
      dataIndex: 'systemDecisionStart',
      key: 'systemDecisionStart',
      width: 125,
      className: 'table_system_color'
      // sorter: (a, b) => moment(a.systemDecisionStart, 'DD.MM.YYYY HH:mm').unix() - moment(b.systemDecisionStart, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Количество привязанных обращений', // null
      dataIndex: 'ticketCount',
      key: 'ticketCount',
      width: 120,
      className: 'table_system_color'
      // sorter: (a, b) => a.ticketCount - b.ticketCount
    },
    {
      title: 'Сотрудник поддержки, контролирующий решение', // null
      dataIndex: 'userControl',
      key: 'userControl',
      width: 160,
      className: 'table_system_color',
      ...searchFilter('userControl', {
        type: 'checkbox',
        options: staff.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Статус решения системной проблемы',
      dataIndex: 'systemDecisionStatus',
      key: 'systemDecisionStatus',
      className: 'table_system_color',
      width: 125,
      ...searchFilter('systemDecisionStatus', {
        type: 'checkbox',
        options: [
          {
            text: 'Новая',
            value: 'Новая'
          },
          {
            text: 'Запланирована в бэклог',
            value: 'Запланирована в бэклог'
          },
          {
            text: 'Запланирована в текущий спринт',
            value: 'Запланирована в текущий спринт'
          },
          {
            text: 'Решена',
            value: 'Решена'
          },
          {
            text: 'Закрыта',
            value: 'Закрыта'
          }
        ]
      })
    },
    {
      title: 'Ответственный за реализацию решения', //null
      dataIndex: 'userSystemDecision',
      key: 'userSystemDecision',
      className: 'table_system_color',
      width: 170,
      ...searchFilter('userSystemDecision', {
        type: 'checkbox',
        options: problemUsers.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Время, затраченное на разработку решения системной проблемы',
      dataIndex: 'systemDecisionDelta',
      key: 'systemDecisionDelta',
      className: 'table_system_color',
      width: 200,
      sorter: (a, b) =>
        parseTimeToMins(a.systemDecisionDelta) -
        parseTimeToMins(b.systemDecisionDelta)
    },
    {
      title: 'Описание системной проблемы',
      dataIndex: 'systemDecisionDescription',
      key: 'systemDecisionDescription',
      className: 'table_system_color',
      width: 200,
      ...searchFilter('systemDecisionDescription', {
        type: 'text'
      })
    },
    {
      title: 'Описание решения системной проблемы',
      dataIndex: 'systemDecisionDecision',
      key: 'systemDecisionDecision',
      className: 'table_system_color',
      width: 200,
      ...searchFilter('systemDecisionDecision', {
        type: 'text'
      })
    }
  ]
}

// eslint-disable-next-line no-unused-vars
export const pColumns = ({
  channels,
  problemPlaces,
  merchants,
  staff,
  softwares,
  problemUsers
}) => {
  return [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Название системной проблемы',
      dataIndex: 'name',
      key: 'name',
      ...searchFilter('name', {
        type: 'text'
      })
    },
    {
      title: 'Время регистрации системной проблемы',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment(a.createdAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.createdAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Сотрудник зарегистрировавший системную проблему',
      key: 'creator',
      dataIndex: 'creator',
      filters: staff.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.creator === value
    },
    {
      title: 'Количество привязанных обращений',
      key: 'ticketCount',
      dataIndex: 'ticketCount',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Описание системной проблемы',
      dataIndex: 'description',
      key: 'description',
      ...searchFilter('description', {
        type: 'text'
      })
    },
    {
      title: 'Время взятия в работу системной проблемы',
      dataIndex: 'startedAt',
      key: 'startedAt',
      sorter: (a, b) =>
        moment(a.startedAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.startedAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Сотрудник поддержки взявший в работу',
      dataIndex: 'userTaken',
      key: 'userTaken',
      filters: staff.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.userTaken === value
    },
    {
      title: 'Время ожидания взятия в работу',
      dataIndex: 'deltaTaken',
      key: 'deltaTaken',
      sorter: (a, b) =>
        parseTimeToMins(a.deltaTaken) - parseTimeToMins(b.deltaTaken)
    },
    {
      title: 'Статус решения системной проблемы',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Новая',
          value: 'Новая'
        },
        {
          text: 'Запланирована в бэклог',
          value: 'Запланирована в бэклог'
        },
        {
          text: 'Запланирована в текущий спринт',
          value: 'Запланирована в текущий спринт'
        },
        {
          text: 'Решена',
          value: 'Решена'
        },
        {
          text: 'Закрыта',
          value: 'Закрыта'
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0
    },
    {
      title: 'Ответственный за реализацию решения',
      dataIndex: 'responsible',
      key: 'responsible',
      filters: problemUsers.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.responsible === value
    },
    {
      title: 'Время затраченное на разработку решения системной проблемы',
      dataIndex: 'deltaClose',
      key: 'deltaClose',
      sorter: (a, b) =>
        parseTimeToMins(a.deltaClose) - parseTimeToMins(b.deltaClose)
    },
    {
      title: 'Описание решения систмной проблемы',
      dataIndex: 'decision',
      key: 'decision',
      ...searchFilter('decision', {
        type: 'text'
      })
    },
    {
      title: 'Время запуска решения в продакшен',
      dataIndex: 'productionTime',
      key: 'productionTime',
      sorter: (a, b) =>
        moment(a.productionTime, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.productionTime, 'DD.MM.YYYY HH:mm').unix()
    }
  ]
}

export const mColumns = [
  {
    title: 'Мерчант',
    dataIndex: 'name'
  },
  {
    title: 'Тип обращения',
    dataIndex: 'type'
  },
  {
    title: 'Количество',
    dataIndex: 'count'
  },
  {
    title: 'Ср. время закрытия',
    dataIndex: 'averagetime'
  },
  {
    title: 'Модули',
    dataIndex: 'softwares'
  }
]

export const initialColumns = {
  id: '№ Обращения',
  currentStatus: 'Текущий статус',
  type: 'Тип обращения',
  clientName: 'Источник',
  clientPhone: 'Телефон источника',
  clientEmail: 'Email источника',
  problemPlace: 'Место проблемы(от пользователя)',
  truthKiosk: 'Адрес места проблемы (если есть)',
  channel: 'Канал обращения',
  merchant: 'Мерчант',
  createdAt: 'Дата и время обращения',
  detectedAt: 'Дата и время проявления проблемы',
  description: 'Описание проблемы на языке пользователя',
  analysUser: 'Сотрудник, взявший в работу',
  startWorkDelta: 'Время реакции на обращение',
  analysStartedAt: 'Время взятия в работу',
  software: 'Локализация проблемы (если есть)',
  analys: 'Результат анализа (описание на тех языке)',
  analysDelta: 'Время затраченное на анализ обращения',
  decisionUser: 'Сотрудник, выполнивший временное решение',
  tempDecision: 'Описание временного решения',
  decisionStartedAt: 'Время применения временного решения',
  decisionDelta: 'Время затраченное на решение по обращению',
  systemDecisionStart: 'Время регистрации системной проблемы',
  ticketCount: 'Количество привязанных обращений',
  userControl: 'Сотрудник поддержки, контролирующий решение',
  systemDecisionDescription: 'Описание системной проблемы',
  systemDecisionStatus: 'Статус решения системной проблемы',
  userSystemDecision: 'Ответственный за реализацию решения',
  systemDecisionDelta:
    'Время, затраченное на разработку решения системной проблемы',
  systemDecisionDecision: 'Описание решения системной проблемы',
  productionTime: 'Время запуска решения в продакшен'
}

export const problemInitialColumns = {
  id: '№',
  name: 'Название системной проблемы',
  createdAt: 'Время регистрации системной проблемы',
  creator: 'Сотрудник зарегистрировавший системную проблему',
  ticketCount: 'Количество привязанных обращений',
  description: 'Описание системной проблемы',
  startedAt: 'Время взятия в работу системной проблемы',
  userTaken: 'Сотрудник поддержки взявший в работу',
  deltaTaken: 'Время ожидания взятия в работу',
  status: 'Статус решения системной проблемы',
  responsible: 'Ответственный за реализацию решения',
  deltaClose: 'Время затраченное на разработку решения системной проблемы',
  decision: 'Описание решения систмной проблемы',
  productionTime: 'Время запуска решения в продакшен'
}

export const initialSupportCilumns = {}

export const rColumns = ({
  channels,
  problemPlaces,
  merchants,
  staff,
  softwares,
  problemUsers
}) => {
  return [
    {
      title: '№ Обращения',
      dataIndex: 'id',
      key: 'id',
      width: 130,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Текущий статус',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      width: 120,
      ...searchFilter('currentStatus', {
        type: 'checkbox',
        options: [
          {
            text: 'Анализ обращения',
            value: 'Анализ обращения'
          },
          {
            text: 'Закрыто',
            value: 'Закрыто'
          },
          {
            text: 'Временное решение',
            value: 'Временное решение'
          },
          {
            text: 'Новое обращение',
            value: 'Новое обращение'
          }
        ]
      })
    },

    {
      title: 'Имя пользователя',
      dataIndex: 'clientName',
      key: 'clientName',
      width: 120,
      className: 'table_users_color',
      ...searchFilter('clientName', {
        type: 'text'
      })
    },

    {
      title: 'Телефон пользователя',
      dataIndex: 'clientPhone',
      key: 'clientPhone',
      width: 160,
      className: 'table_users_color',
      ...searchFilter('clientPhone', {
        type: 'text'
      })
    },

    {
      title: 'Канал обращения',
      dataIndex: 'channel',
      key: 'channel',
      width: 120,
      className: 'table_acception_color',
      ...searchFilter('channel', {
        type: 'checkbox',
        options: channels.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Место проблемы(от пользователя)',
      dataIndex: 'problemPlace',
      key: 'problemPlace',
      className: 'table_acception_color',
      width: 190,
      ...searchFilter('problemPlace', {
        type: 'checkbox',
        options: problemPlaces.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Описание проблемы (от пользователя)',
      dataIndex: 'description',
      key: 'description',
      width: 350,
      className: 'table_acception_color',
      ...searchFilter('description', {
        type: 'text'
      })
    },
    {
      title: 'Дата и время обращения',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      className: 'table_acception_color',
      sorter: (a, b) =>
        moment(a.createdAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.createdAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Дата и время проявления проблемы у пользователя',
      dataIndex: 'detectedAt',
      key: 'detectedAt',
      width: 120,
      className: 'table_acception_color',
      sorter: (a, b) =>
        moment(a.detectedAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.detectedAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Сотрудник, взявший в работу',
      dataIndex: 'analysUser',
      key: 'analysUser',
      width: 123,
      className: 'table_acception_color',
      ...searchFilter('analysUser', {
        type: 'checkbox',
        options: staff.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Время реакции на обращение',
      dataIndex: 'startWorkDelta',
      key: 'startWorkDelta',
      width: 125,
      className: 'table_acception_color',
      sorter: (a, b) =>
        parseTimeToMins(a.startWorkDelta) - parseTimeToMins(b.startWorkDelta)
    },

    {
      title: 'Тип обращения',
      dataIndex: 'type',
      key: 'type',
      width: 125,
      className: 'table_analys_color',
      ...searchFilter('type', {
        type: 'checkbox',
        options: [
          {
            text: 'Проблема',
            value: 'Проблема'
          },
          {
            text: 'Консультация',
            value: 'Консультация'
          },
          {
            text: 'Запрос на улучшение',
            value: 'Запрос на улучшение'
          }
        ]
      })
    },
    {
      title: 'Мерчант',
      dataIndex: 'merchant',
      key: 'merchant',
      width: 120,
      className: 'table_analys_color',
      ...searchFilter('merchant', {
        type: 'checkbox',
        options: merchants.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Проблемное место',
      dataIndex: 'problemPlace',
      key: 'problemPlace',
      width: 250,
      className: 'table_analys_color',
      ...searchFilter('problemPlace', {
        type: 'text'
      })
    },
    {
      title: 'Адрес киоска',
      dataIndex: 'truthKiosk',
      key: 'truthKiosk',
      width: 250,
      className: 'table_analys_color',
      ...searchFilter('truthKiosk', {
        type: 'text'
      })
    },
    {
      title: 'Результат анализа (описание на тех языке)',
      dataIndex: 'analys',
      key: 'analys',
      className: 'table_analys_color',
      width: 350,
      ...searchFilter('analys', {
        type: 'text'
      })
    },
    {
      title: 'Сотрудник, выполнивший анализ обращения',
      dataIndex: 'analysUser',
      key: 'analysUser',
      className: 'table_analys_color',
      width: 350,
      ...searchFilter('analys', {
        type: 'text'
      })
    },

    {
      title: 'Время затраченное на анализ обращения',
      dataIndex: 'analysTime',
      key: 'analysTime',
      width: 120,
      className: 'table_analys_color'
      // sorter: (a, b) => moment(a.analysStartedAt, 'DD.MM.YYYY HH:mm').unix() - moment(b.analysStartedAt, 'DD.MM.YYYY HH:mm').unix()
    },

    {
      title: 'Описание временного решения',
      dataIndex: 'tempDecision',
      key: 'tempDecision',
      className: 'table_decision_color',
      width: 250,
      ...searchFilter('tempDecision', {
        type: 'text'
      })
    },

    {
      title: 'Теги',
      dataIndex: 'tags',
      key: 'tags',
      className: 'table_decision_color',
      width: 250
    },
    {
      title: 'Сотрудник, выполнивший временное решение',
      dataIndex: 'decisionUser',
      key: 'decisionUser',
      className: 'table_decision_color',
      width: 170,
      filters: staff.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.decisionUser === value
    },

    {
      title: 'Время затраченное на решение по обращению',
      dataIndex: 'decisionDelta',
      key: 'decisionDelta',
      width: 123,
      className: 'table_decision_color',
      sorter: (a, b) =>
        parseTimeToMins(a.decisionDelta) - parseTimeToMins(b.decisionDelta)
    },
    {
      title: 'Сотрудник, закрывший обращение',
      dataIndex: 'userClosedTicket',
      key: 'userClosedTicket',
      width: 123,
      className: 'table_decision_color'
    },

    {
      title: 'Описание системной проблемы',
      dataIndex: 'systemDecisionDescription',
      key: 'systemDecisionDescription',
      className: 'table_system_color',
      width: 200,
      ...searchFilter('systemDecisionDescription', {
        type: 'text'
      })
    },
    {
      title: 'Количество привязанных обращений', // null
      dataIndex: 'ticketCount',
      key: 'ticketCount',
      width: 120,
      className: 'table_system_color'
      // sorter: (a, b) => a.ticketCount - b.ticketCount
    },

    {
      title: 'Сотрудник зарегистрировавший системную проблему',
      key: 'creator',
      dataIndex: 'creator',
      width: 120,
      className: 'table_system_color',
      filters: staff.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.creator === value
    },

    {
      title: 'Время регистрации системной проблемы',
      dataIndex: 'systemDecisionCreate',
      key: 'systemDecisionCreate',
      width: 125,
      className: 'table_system_color'
      // sorter: (a, b) => moment(a.systemDecisionStart, 'DD.MM.YYYY HH:mm').unix() - moment(b.systemDecisionStart, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Время взятия в работу системной проблемы',
      dataIndex: 'systemDecisionStart',
      key: 'systemDecisionStart',
      className: 'table_system_color',
      width: 125,
      sorter: (a, b) =>
        moment(a.systemDecisionStart, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.systemDecisionStart, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Время ожидания взятия в работу',
      dataIndex: 'deltaTaken',
      key: 'deltaTaken',
      width: 160,
      className: 'table_system_color',
      sorter: (a, b) =>
        parseTimeToMins(a.deltaTaken) - parseTimeToMins(b.deltaTaken)
    },
    {
      title: 'Сотрудник поддержки, контролирующий решение', // null
      dataIndex: 'userControl',
      key: 'userControl',
      width: 160,
      className: 'table_system_color',
      ...searchFilter('userControl', {
        type: 'checkbox',
        options: staff.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Статус решения системной проблемы',
      dataIndex: 'systemDecisionStatus',
      key: 'systemDecisionStatus',
      className: 'table_system_color',
      width: 125,
      ...searchFilter('systemDecisionStatus', {
        type: 'checkbox',
        options: [
          {
            text: 'Новая',
            value: 'Новая'
          },
          {
            text: 'Запланирована в бэклог',
            value: 'Запланирована в бэклог'
          },
          {
            text: 'Запланирована в текущий спринт',
            value: 'Запланирована в текущий спринт'
          },
          {
            text: 'Решена',
            value: 'Решена'
          },
          {
            text: 'Закрыта',
            value: 'Закрыта'
          }
        ]
      })
    },
    {
      title: 'Описание решения системной проблемы',
      dataIndex: 'systemDecisionDecision',
      key: 'systemDecisionDecision',
      className: 'table_system_color',
      width: 200,
      ...searchFilter('systemDecisionDecision', {
        type: 'text'
      })
    },
    {
      title: 'Время запуска решения в продакшен',
      dataIndex: 'productionTime',
      key: 'productionTime',
      width: 120,
      className: 'table_system_color'
      // sorter: (a, b) => moment(a.productionTime, 'DD.MM.YYYY HH:mm').unix() - moment(b.productionTime, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Время затраченное до закрытия системной проблемы',
      dataIndex: 'deltaClose',
      key: 'deltaClose',
      width: 120,
      className: 'table_system_color',
      sorter: (a, b) =>
        parseTimeToMins(a.deltaClose) - parseTimeToMins(b.deltaClose)
    }
  ]
}

// eslint-disable-next-line no-unused-vars
export const systemProblemColumns = ({
  channels,
  problemPlaces,
  merchants,
  staff,
  softwares,
  problemUsers
}) => {
  return [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Описание системной проблемы',
      dataIndex: 'description',
      key: 'description',
      ...searchFilter('description', {
        type: 'text'
      })
    },
    {
      title: 'Количество привязанных обращений',
      key: 'ticketCount',
      dataIndex: 'ticketCount',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Сотрудник зарегистрировавший системную проблему',
      key: 'creator',
      dataIndex: 'creator',
      filters: staff.map((i) => ({ text: i.name, value: i.name })),
      onFilter: (value, record) => record.creator === value
    },
    {
      title: 'Время регистрации системной проблемы',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment(a.createdAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.createdAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Время взятия в работу системной проблемы',
      dataIndex: 'startedAt',
      key: 'startedAt',
      sorter: (a, b) =>
        moment(a.startedAt, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.startedAt, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Время ожидания взятия в работу',
      dataIndex: 'deltaTaken',
      key: 'deltaTaken',
      sorter: (a, b) =>
        parseTimeToMins(a.deltaTaken) - parseTimeToMins(b.deltaTaken)
    },
    {
      title: 'Сотрудник поддержки, контролирующий решение', // null
      dataIndex: 'userControl',
      key: 'userControl',
      width: 160,

      ...searchFilter('userControl', {
        type: 'checkbox',
        options: staff.map((i) => ({ text: i.name, value: i.name }))
      })
    },
    {
      title: 'Статус решения системной проблемы',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Новая',
          value: 'Новая'
        },
        {
          text: 'Запланирована в бэклог',
          value: 'Запланирована в бэклог'
        },
        {
          text: 'Запланирована в текущий спринт',
          value: 'Запланирована в текущий спринт'
        },
        {
          text: 'Решена',
          value: 'Решена'
        },
        {
          text: 'Закрыта',
          value: 'Закрыта'
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0
    },
    {
      title: 'Описание решения систмной проблемы',
      dataIndex: 'decision',
      key: 'decision',
      ...searchFilter('decision', {
        type: 'text'
      })
    },

    {
      title: 'Время запуска решения в продакшен',
      dataIndex: 'productionTime',
      key: 'productionTime',
      sorter: (a, b) =>
        moment(a.productionTime, 'DD.MM.YYYY HH:mm').unix() -
        moment(b.productionTime, 'DD.MM.YYYY HH:mm').unix()
    },
    {
      title: 'Время затраченное на разработку решения системной проблемы',
      dataIndex: 'deltaClose',
      key: 'deltaClose',
      sorter: (a, b) =>
        parseTimeToMins(a.deltaClose) - parseTimeToMins(b.deltaClose)
    }
  ]
}
