import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { Input } from 'antd'

const MASK = '+7-(999)-999-99-99'
const EMPTY_MASK = '+7-(___)-___-__-__'

const PhoneNumberInput = (props) => {
  const { onChange, value, ...rest } = props

  const [innerValue, setInnerValue] = useState(value)

  const handleChange = (event) => {
    const newValue = event.target.value
    let outerValue = newValue

    if (newValue === EMPTY_MASK) outerValue = ''
    value !== outerValue && onChange(outerValue)

    setInnerValue(newValue)
  }

  return (
    <InputMask onChange={handleChange} value={innerValue} mask={MASK}>
      {(props) => <Input {...rest} {...props} />}
    </InputMask>
  )
}

export default PhoneNumberInput
