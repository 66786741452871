import { Select } from 'antd'
import React from 'react'
const { Option } = Select

const SingleSelect = ({
  showArrow = true,
  value,
  options,
  placeholder,
  className,
  onChange,
  defaultValue,
  showSearch = false,
  ...rest
}) => {
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      showArrow={showArrow}
      defaultValue={defaultValue}
      value={value}
      optionFilterProp='children'
      showSearch={showSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...rest}
    >
      {options.map((o) => (
        <Option key={o.id} value={o.id}>
          {o.label}
        </Option>
      ))}
    </Select>
  )
}

export default SingleSelect
