import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Button, Typography, Table, Form, Input } from 'antd'
import { useHistory } from 'react-router-dom'
import { CautionModal } from 'components/CautionModal'
import { MerchantModal } from './MerchantModal'
import { useMerchant } from 'api/Merchant'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import debounce from 'lodash.debounce'
import styles from './styles.module.scss'

const { Title } = Typography

import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'

const Merchants = () => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const { getAllMerchants, deleteMerchant } = useMerchant()
  const [data, setData] = useState([])
  const [deletingData, setDeletingData] = useState({
    modal: false,
    name: '',
    id: null
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [merchant, setMerchant] = useState([])

  const history = useHistory()

  const getData = async () => {
    try {
      setLoading(true)
      setData(await getAllMerchants({ q: search }))
    } catch (e) {
      // empty
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteMerchant = async () => {
    try {
      const res = await deleteMerchant(deletingData.id)
      if (res) {
        setData(data.filter((merchant) => merchant.id !== deletingData.id))
      }
    } catch (e) {
      // empty
    } finally {
      setDeletingData({ modal: false, id: null, name: '' })
    }
  }

  const updateDeleteStateHandler = (merchant) => (ev) => {
    ev.stopPropagation()
    setDeletingData({ modal: true, id: merchant.id, name: merchant.name })
  }

  const closeDeletingModalHandler = () => {
    setDeletingData({ modal: false, id: null, name: '' })
  }

  const handleEditSoftware = (value) => (ev) => {
    ev.stopPropagation()
    setMerchant(value)
    setModalOpen(true)
  }

  const highlight = (paragraph, sl) => {
    let s = sl
    s = s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const replace = new RegExp(sl, 'gi')

    if (s.length > 0) {
      return paragraph.replace(
        replace,
        `<span class=${styles.highlight}>$&</span>`
      )
    } else {
      return paragraph
    }
  }

  const renderHtmlHighlight = useCallback(
    (paragraph, search) => {
      const s = search.split(' ').filter((item) => item.length !== 0)
      let p = paragraph

      s.forEach((item) => {
        let para = highlight(p, item.trim())
        p = para
      })

      return p
    },
    [search]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        render: (text) => {
          return (
            <div
              contentEditable='true'
              dangerouslySetInnerHTML={{
                __html: renderHtmlHighlight(text, search)
              }}
            ></div>
          )
        }
      },
      {
        title: 'Адрес',
        dataIndex: 'address',
        key: 'address',
        render: (text, record) => {
          const kiosk = record.merchantKiosks[0]
          if (kiosk) {
            return (
              <div
                contentEditable='true'
                dangerouslySetInnerHTML={{
                  __html: renderHtmlHighlight(
                    `${kiosk.country} ${kiosk.city} ${kiosk.street} ${kiosk.house}`,
                    search
                  )
                }}
              ></div>
            )
          }
          return ''
        }
      },
      {
        key: 'id',
        dataIndex: 'id',
        render: (text, record) => (
          <div className={styles.tableButtons}>
            <EditOutlined
              className={styles.icons}
              onClick={handleEditSoftware(record)}
            />
            {/* <DeleteOutlined
              className={styles.icons}
              onClick={updateDeleteStateHandler(record)}
            /> */}
          </div>
        )
      }
    ],
    [search]
  )

  const columns2 = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'number',
        key: 'number',
        render: (text, record) => {
          return (
            <>
              Киоск{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: renderHtmlHighlight(`${text}`, search)
                }}
              ></span>
            </>
          )
        }
      },
      {
        title: '',
        key: 'address',
        dataIndex: 'address',
        render: (text, record) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: renderHtmlHighlight(
                  `${record.country} ${record.city} ${record.street} ${record.house}`,
                  search
                )
              }}
            ></div>
          )
        }
      },
      {
        key: '',
        dataIndex: ''
      }
    ],
    [search]
  )

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleJustCloseModal = () => {
    setModalOpen(false)
    setMerchant([])
  }

  const handleOnCloseModal = () => {
    handleJustCloseModal()
    getData()
  }

  const rowClickHandler = (record, ...other) => {
    return {
      onClick: () => {
        history.push(`/admin/merchant/${record.id}`)
      },
      onMouseDown: (e) => {
        if (e.button === 1)
          window.open(`/admin/merchant/${record.id}`, '_blank')
      }
    }
  }

  const expandedRowRender = (record) => (
    <Table
      className={styles.table}
      tableLayout={'fixed'}
      dataSource={record.merchantKiosks}
      columns={columns2}
      loading={loading}
      pagination={false}
      rowClassName={styles.row}
      showHeader={false}
    />
  )

  const handleSearch = (value) => {
    setSearch(value)
  }
  const rowExpandable = (record) => record.name !== 'Not Expandable'
  const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), [])

  useEffect(() => {
    getData()
  }, [search])

  const rowKeys = useMemo(() => {
    return data.map((item) => item.id)
  }, [data])

  const openRows = useMemo(() => {
    return search.length > 0
  }, [search])

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список мерчантов
        </Title>
        {/* <Button type='primary' onClick={handleOpenModal}>
          Добавить
        </Button> */}
      </div>

      <div>
        <Form
          wrapperCol={{
            span: 10
          }}
          layout='vertical'
        >
          <Form.Item label='Поиск' className={styles.formItem}>
            <Input
              style={{ width: '250px' }}
              placeholder='Поиск'
              onChange={(e) => {
                debouncedChangeHandler(e.target.value)
              }}
            />
          </Form.Item>
        </Form>
      </div>

      <Table
        expandedRowKeys={openRows ? rowKeys : false}
        className={styles.table}
        tableLayout={'fixed'}
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        rowKey={(record) => record.id}
        onRow={rowClickHandler}
        // onClick={(e) => console.log(e.target.value)}
        rowClassName={styles.row}
        expandRowByClick={true}
        expandable={{
          expandedRowRender,
          rowExpandable,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <CaretUpFilled
                style={{ fontSize: '20px' }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <CaretDownFilled
                style={{ fontSize: '20px' }}
                onClick={(e) => onExpand(record, e)}
              />
            )
        }}
      />

      <MerchantModal
        merchant={merchant}
        justClose={handleJustCloseModal}
        onClose={handleOnCloseModal}
        isOpen={modalOpen}
      />

      <CautionModal
        isOpen={deletingData.modal}
        onClose={closeDeletingModalHandler}
        onFinish={handleDeleteMerchant}
        title={`Вы уверены что хотите удалить мерчанта: ${deletingData.name}?`}
      />
    </div>
  )
}

export default Merchants
