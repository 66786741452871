import React from 'react'
import { formatTime } from 'utils/helpers'
import { useMerchantBotAPI } from 'api/merchantBot'
import { Row, Col, Input, Button, Table, Form } from 'antd'
import { CreateMessageModal } from './modals/CreateMessageModal'
import Pagination from 'components/Pagination'
import { useDebouncedCallback } from 'use-debounce/lib'

const FILTER_INITIAL = {
  search: '',
  page: 1,
  perPage: 10
}

export const MerchantBotMessages = () => {
  const { fetchMessages, loading } = useMerchantBotAPI()

  const [createMessageModalOpen, setCreateMessageModalOpen] =
    React.useState(false)
  const [messages, setMessages] = React.useState([])
  const [filter, setFilter] = React.useState({ ...FILTER_INITIAL })
  const [pagination, setPagination] = React.useState({})

  const getMessages = useDebouncedCallback(async () => {
    const res = await fetchMessages(filter)
    setMessages(res.data)
    setPagination(res.pagination)
  }, 300)

  React.useEffect(() => {
    getMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const columns = React.useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        width: '3%'
      },
      {
        title: 'Сообщение',
        dataIndex: 'text',
        key: 'text',
        width: '62%'
      },
      {
        title: 'Доставлено',
        dataIndex: 'botMessage',
        width: '27%',
        key: 'botMessage',
        render: (value) => value.map((chat) => chat.chat.name).join(', ')
      },
      {
        title: 'Отправлено',
        dataIndex: 'createdAt',
        width: '8%',
        key: 'createdAt',
        render: (value) => formatTime(value)
      }
    ],
    []
  )

  const handleChangeFilter = (name) => (event) => {
    setFilter({ ...filter, [name]: event.target.value })
  }

  const handleChangePagination = (value) => {
    setFilter({ ...filter, ...value })
  }

  const handleCloseModal = () => {
    setCreateMessageModalOpen(false)
    getMessages()
  }

  return (
    <div>
      <Form
        wrapperCol={{
          span: 10
        }}
        layout='vertical'
      >
        <Row
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between'
          }}
        >
          <Col span={6}>
            <Form.Item>
              <Input
                value={filter.search}
                style={{ width: 300 }}
                placeholder='Поиск по тексту'
                onChange={handleChangeFilter('search')}
              />
            </Form.Item>
          </Col>
          <Col span={33}>
            <Button
              style={{ marginBottom: 20 }}
              type='primary'
              onClick={() => setCreateMessageModalOpen(true)}
            >
              Отправить сообщение
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={messages}
        columns={columns}
        loading={loading}
        pagination={false}
        scroll={{ x: 1200 }}
      />
      <Pagination onChange={handleChangePagination} pagination={pagination} />

      <CreateMessageModal
        open={createMessageModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  )
}
