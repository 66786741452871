import axios from 'axios'
import alert from './alert'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000,
  withCredentials: true
})

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    window.location.replace('/')
  }
  return Promise.reject(error)
}

const resHandler = (response) => {
  let { data } = response

  if (data.error) {
    alert.error(data.error.message)
    return Promise.reject(data.error)
  }

  return Promise.resolve(data)
}

const reqHandler = (config) => {
  config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
  return config
}

axiosInstance.interceptors.request.use(reqHandler)
axiosInstance.interceptors.response.use(resHandler, errorHandler)

export default axiosInstance
