import React from 'react'
import MultiSelect from 'components/MultiSelect'
import { useMerchantBotAPI } from 'api/merchantBot'
import { Row, Col, Input, Button, Modal } from 'antd'

export const CreateMessageModal = ({ open, onClose }) => {
  const [selectedMerchants, setSelectedMerchants] = React.useState([])
  const [chatIds, setChatIds] = React.useState([])
  const [chats, setChats] = React.useState([])
  const [message, setMessage] = React.useState('')

  const { fetchAllChats, fetchSendMessage } = useMerchantBotAPI()

  const getAllChats = async () => {
    const res = await fetchAllChats()
    setChats(res)
    setSelectedMerchants(res.map((item) => item.chatId))
  }

  React.useEffect(() => {
    setChatIds(
      chats.flatMap((chat) =>
        selectedMerchants.includes(chat.chatId) ? chat.id : []
      )
    )
  }, [chats, selectedMerchants])

  React.useEffect(() => {
    if (open === true) {
      getAllChats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const chatsOptions = React.useMemo(() => {
    return chats?.map((item) => ({ id: item.chatId, label: item.name }))
  }, [chats])

  const handleChange = (value) => {
    setSelectedMerchants(value)
  }

  const handleSubmit = async () => {
    const data = { message, chats: selectedMerchants, chatIds }
    await fetchSendMessage(data)
    handleClose()
  }

  const handleClose = () => {
    setMessage('')
    onClose()
  }

  return (
    <Modal footer={null} visible={open} onCancel={handleClose}>
      <div>
        <Row style={{ marginBottom: 30 }}>
          <Col span={24}>
            <MultiSelect
              style={{ width: 350 }}
              mode={'multiple'}
              value={selectedMerchants}
              options={chatsOptions}
              onChange={handleChange}
              placeholder='Кому отправить'
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input.TextArea
              value={message}
              style={{ width: 500 }}
              onChange={(val) => setMessage(val.target.value)}
              // className={styles.answerTextarea}
              rows='6'
            />
          </Col>
        </Row>
        <Button style={{ marginTop: 20 }} type='primary' onClick={handleSubmit}>
          Отправить
        </Button>
      </div>
    </Modal>
  )
}
