import { useParams } from 'react-router'
import { Card, Typography, Spin, Button } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'

import Comments from './Comments'
import AttachTab from './AttachTab'
import DecisionTab from './DecisionTab'

import StatusIndicator from 'components/StatusIndicator'
import ConditionalRender from 'components/ConditionalRender'
import { EditModal } from './modals'

import axios from 'utils/axios'
import { formatTime, makeProblemColorIndicator } from 'utils/helpers'

import { useProfile } from 'hooks/useContext'

import 'antd/dist/antd.css'
import styles from './styles.module.scss'
import { EditOutlined } from '@ant-design/icons'

const { Title } = Typography

const ProblemCard = () => {
  const params = useParams()
  const { user } = useProfile()

  const [titleKey, setTitleKey] = useState('attach')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/problems/${params.id}`)
      const colorIndicator = makeProblemColorIndicator(res.data.status?.name)
      setData({ ...res.data, colorIndicator })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [params.id])

  useEffect(() => {
    getData()
  }, [getData])

  const handleChangeTab = (key) => {
    setTitleKey(key)
  }

  const applyInWork = async () => {
    try {
      setButtonLoading(true)
      await axios.put(`/problems/${params.id}/startWork`)
    } catch (e) {
      console.log(e)
    } finally {
      setButtonLoading(false)
      getData()
    }
  }

  const handleTakeWork = async () => {
    try {
      setButtonLoading(true)
      await axios.put(`/problems/${data.id}/delegate`)
      getData()
    } catch (e) {
      console.log(e)
    } finally {
      setButtonLoading(false)
    }
  }

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen)
  }
  const justCloseEditModal = () => {
    setEditModalOpen(false)
  }
  const closeEditModal = () => {
    justCloseEditModal()
    getData()
  }

  const tabList = [
    {
      key: 'attach',
      tab: 'Привязанные обращения'
    },
    {
      key: 'decision',
      tab: 'Системное решение'
    },
    {
      key: 'comments',
      tab: 'Комментарии'
    }
  ]

  const contentList = {
    attach: <AttachTab data={data} update={getData} />,
    decision: <DecisionTab data={data} update={getData} />,
    comments: <Comments data={data} update={getData} />
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Системная проблема №{data.id}
        </Title>
        <ConditionalRender roles={[1, 2]}>
          {data.user?.id !== user.id && data.startedWorkAt !== null && (
            <Button
              loading={buttonLoading}
              type='primary'
              className={styles.applyButton}
              size='large'
              onClick={handleTakeWork}
            >
              Взять на себя
            </Button>
          )}

          {data.startedWorkAt === null && (
            <Button
              loading={buttonLoading}
              type='primary'
              className={styles.applyButton}
              size='large'
              onClick={applyInWork}
            >
              Взять в работу
            </Button>
          )}
        </ConditionalRender>

        <div className={styles.leftInfoContainer}>
          <Title level={2}>
            <StatusIndicator
              content={data.status?.name}
              indicator={data.colorIndicator}
            />
          </Title>
        </div>
      </div>

      <Title className={styles.currentUser} level={4}>
        {data.user?.name}
      </Title>

      <div>
        {loading ? (
          <Spin />
        ) : (
          <InfoCard data={data} modalClick={toggleEditModal} />
        )}
      </div>

      {data.startedWorkAt && (
        <Card
          style={{ width: '100%' }}
          tabList={tabList}
          activeTabKey={titleKey}
          onTabChange={handleChangeTab}
        >
          {contentList[titleKey]}
        </Card>
      )}
      <EditModal
        isOpen={editModalOpen}
        justClose={justCloseEditModal}
        data={data}
        onClose={closeEditModal}
      />
    </div>
  )
}

export default ProblemCard

const InfoCard = (props) => {
  const { data, modalClick } = props

  const handleEditInforamtion = () => {
    modalClick()
  }
  return (
    <Card className={styles.infoCard}>
      <ul className={styles.clientCardList}>
        <li className={styles.clientCardListItem}>
          Cоздал:
          <p>{data.creator?.name}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Ответственный за реализацию решения:
          <p>{data.responsible?.name || '-'}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Время формирования запроса:
          <p>{formatTime(data.createdAt)}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Время взятия в работу ответственным:
          <p>{data.startedWorkAt ? formatTime(data.startedWorkAt) : '-'}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Локализация:
          <p>{data.localization}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Название:
          <p>{data.name}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Описание:
          <p>{data.description}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Описание системного решения:
          <p>{data.decision ? data.decision : 'Отсутствует'}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Время запуска решения в продакшен:
          <p>
            {data.productionTime
              ? formatTime(data.productionTime)
              : 'Отсутствует'}
          </p>
        </li>
        <li className={styles.clientCardListItem}>
          Привязанные обращения (не закр./все):
          <p>{`${data.ticket?.filter((i) => i.currentStatusId < 4)?.length}/${
            data.ticket?.length
          }`}</p>
        </li>
      </ul>
      <ConditionalRender roles={[1, 2]}>
        {data.startedWorkAt && (
          <EditOutlined
            onClick={handleEditInforamtion}
            className={styles.editButton}
          />
        )}
      </ConditionalRender>
    </Card>
  )
}
