import React from 'react'

import { Typography } from 'antd'

export const Heading = ({ size, text, color = '#3D8FEC' }) => {
  return (
    <Typography.Title level={size} style={{ margin: 0, color }}>
      {text}
    </Typography.Title>
  )
}
