import { Modal } from 'antd'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Button, Form } from 'antd'
import SingleSelect from 'components/SingleSelect'
import axios from 'utils/axios'
import { useProfile } from 'hooks/useContext'
export const OwnerTicketModal = ({
  isOpen,
  onClose,
  ticketId,
  saveAndClose,
  currentUserId
}) => {
  const [editing, setEditing] = useState({ is: false, id: null })

  const [loading, setLoading] = useState(false)

  const { user } = useProfile()
  const [staff, setStaff] = useState([])

  const usersOption = useMemo(() => {
    return staff
      .map((user) => ({
        id: user.id,
        label: `${user.id} | ${user.name}, ${user.email}`
      }))
      .filter((u) => u.id !== currentUserId)
  }, [staff, user])

  const loadStaff = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/user/staff`)
      setEditing({ is: false, id: null })
      setStaff(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadStaff()
  }, [currentUserId])

  const changeSelectHandler = (e) => {
    setEditing({ is: false, id: e })
  }

  const handleSubmit = useCallback(async () => {
    if (editing.id) {
      try {
        setLoading(true)
        setEditing({ is: false, id: null })
        await axios.put(`/ticket/${ticketId}/delegate-ticket`, {
          userId: editing.id
        })
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
        const delegateUser = staff.find((user) => user.id === editing.id).name
        saveAndClose(delegateUser)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing.id])

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={false}
      title={`Делегировать обращение`}
      destroyOnClose={true}
    >
      <Form layout='vertical' onFinish={handleSubmit}>
        <Form.Item label='Выберете пользователя'>
          <SingleSelect options={usersOption} onChange={changeSelectHandler} />
        </Form.Item>
        <Form.Item>
          {editing.id && (
            <Button loading={loading} type='primary' htmlType='submit'>
              Сохранить
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}
