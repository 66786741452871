import { store } from 'react-notifications-component'
import { useHistory } from 'react-router'

export const useNotification = () => {

  const history = useHistory()

  const createNotification = (message, type) => {
    if (history.location.pathname.split('/')[1] !== 'ticket') {
      store.addNotification({
        message: message,
        type,
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__flipOutX"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true
        },
      })
    }
  }

  const createNotificationWithCustomTitle = (title, message) => {
    store.addNotification({
      title: title,
      message: message,
      type: "info",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__zoomIn"],
      animationOut: ["animate__animated", "animate__flipOutX"],
      dismiss: {
        duration: 0,
        onScreen: true,
        pauseOnHover: true
      },
    })
  }

  return { createNotification, createNotificationWithCustomTitle }
}