import { useProfile } from 'hooks/useContext'
import { useState, useCallback } from 'react'
import axios from 'utils/axios'

export const useChangeProfile = () => {
  const [loading, setLoading] = useState(false)

  const { changeProfileEmail, updateUser } = useProfile()

  const changeEmail = useCallback(async (user, newEmail) => {
    try {
      setLoading(true)
      const res = await axios.put('/profile/change-email', {
        id: user.id,
        newEmail
      })
      changeProfileEmail(res.data.email)
    } catch (e) {
      throw new Error(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changePass = useCallback(async (user, password) => {
    try {
      setLoading(true)
      const res = await axios.put('/profile/change-password', {
        id: user.id,
        password
      })
      return res
    } catch (e) {
      throw new Error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const changeNotification = useCallback(async (id, notification) => {
    try {
      setLoading(true)
      const res = await axios.put('/profile/change-notification', {
        id,
        notification
      })
      updateUser(res.data)
      return res.message
    } catch (e) {
      throw new Error(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, changeEmail, changePass, changeNotification }
}
